import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function ChevronLeftIcon({
    style = { width: 10, height: 18 },
    color = 'black',
    height = 18,
    width = 10,
}) {
    return (
        <Svg width={width} height={height} viewBox='0 0 14 24' fill='none'>
            <Path d='M13 1L2 12L13 23' stroke={color} strokeWidth='2' />
        </Svg>
    );
}
