import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeScreen from './screens/HomeScreen';
import LoginScreen from './screens/LoginScreen';
import { RefObject, useRef, useState } from 'react';
import tw from './utils/Tailwind';
import { useAppColorScheme, useDeviceContext } from 'twrnc';
import { useFonts } from 'expo-font';
import AuthProvider from './contexts/AuthContext';
import SearchProvider from './contexts/SearchContext';
import RestaurantScreen from './screens/RestaurantScreen';
import BookingLinksScreen from './screens/BookingLinksScreen';
import AccountScreen from './screens/AccountScreen';
import ConfigProvider from './contexts/ConfigContext';

const Stack = createNativeStackNavigator();

const linking = {
    prefixes: ['https://concierge.dorsia.com'],
    config: {
        screens: {
            Home: '',
            Login: 'login',
            Restaurant: 'book/:id',
            BookingLinks: 'bookings',
            AccountScreen: 'AccountScreen',
        },
    },
};

export default function App() {
    const [navigationIsReady, setNavigationIsReady] = useState(false);
    const navigationRef: RefObject<any> = useRef();

    useDeviceContext(tw, { withDeviceColorScheme: false });
    const [colorScheme, toggleColorScheme, setColorScheme] =
        useAppColorScheme(tw);

    let [fontsLoaded] = useFonts({
        SailecRegular: require('./components/assets/fonts/SailecRegular.otf'),
        SailecMedium: require('./components/assets/fonts/SailecMedium.otf'),
        SailecBold: require('./components/assets/fonts/SailecBold.otf'),
        SailecItalic: require('./components/assets/fonts/Sailec-RegularItalic.otf'),
    });

    return (
        <ConfigProvider>
            <AuthProvider navigationRef={navigationRef}>
                <SearchProvider>
                    <NavigationContainer
                        ref={navigationRef}
                        linking={linking}
                        onReady={() => setNavigationIsReady(true)}
                    >
                        <Stack.Navigator screenOptions={{ headerShown: false }}>
                            <Stack.Group>
                                <Stack.Screen
                                    name='Home'
                                    component={HomeScreen}
                                    options={{ title: 'Restaurants' }}
                                />
                            </Stack.Group>
                            <Stack.Group>
                                <Stack.Screen
                                    name='Restaurant'
                                    component={RestaurantScreen}
                                    options={{ title: 'Send reservation link' }}
                                />
                            </Stack.Group>
                            <Stack.Group>
                                <Stack.Screen
                                    name='BookingLinks'
                                    component={BookingLinksScreen}
                                    options={{ title: 'Booking links' }}
                                />
                            </Stack.Group>
                            <Stack.Group>
                                <Stack.Screen
                                    name='AccountScreen'
                                    component={AccountScreen}
                                    options={{ title: 'My Account' }}
                                />
                            </Stack.Group>
                            <Stack.Group>
                                <Stack.Screen
                                    name='Login'
                                    component={LoginScreen}
                                    options={{ title: 'Login' }}
                                />
                            </Stack.Group>
                        </Stack.Navigator>
                    </NavigationContainer>
                </SearchProvider>
            </AuthProvider>
        </ConfigProvider>
    );
}
