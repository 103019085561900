import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

export default function GuestIcon({
  color = 'black',
  height = 15,
  width = 15,
}) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <Path
        d='M12.3296 12.7794C12.0448 11.9821 11.417 11.2775 10.5438 10.7749C9.67059 10.2724 8.60067 10 7.5 10C6.39933 10 5.32942 10.2724 4.45619 10.7749C3.58297 11.2775 2.95525 11.9821 2.67037 12.7794'
        stroke={color}
        strokeLinecap='round'
      />
      <Circle cx='7.5' cy='5' r='2.5' stroke={color} strokeLinecap='round' />
    </Svg>
  );
}
