import React, {
	createContext,
	PropsWithChildren,
	useEffect,
	useRef,
	useState,
	useContext,
} from "react";
import { Concierge, ConciergeDocument, ConciergeQuery } from "../gql/graphql";
import API from "../utils/API";
import { useNavigation } from "@react-navigation/native";
import LoginScreen from "../screens/LoginScreen";
import HttpClient from "../utils/HttpClient";

const AuthContext = createContext({});

export const useAuthContext = () => useContext(AuthContext);

const AuthProvider = (props: PropsWithChildren<any>) => {
	const [currentUser, setCurrentUser] = useState<Concierge | undefined>();
	const navigationRef = props.navigationRef.current;

	const fetchCurrentUser = () => {
		return HttpClient.graphqlQuery(ConciergeDocument)
			.then((data: ConciergeQuery) => {
				setCurrentUser(data.me);
			})
			.catch((err) => {
				navigationRef.navigate("Login");
			});
	}

	useEffect(() => {
		fetchCurrentUser()
	}, [navigationRef]);

	return (
		<AuthContext.Provider value={{ currentUser, setCurrentUser, fetchCurrentUser }}>
			{props.children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
