import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function SearchIcon({ color, opacity = 1 }) {
  return (
    <Svg
      accessible={true}
      accessibilityLabel='Search Icon'
      fill={color}
      height='20'
      viewBox='0 0 20 20'
      width='20'
      xmlns='http://www.w3.org/2000/svg'
    >
      <Path
        d='M19.6677 18.107L14.7627 13.1962C17.2289 9.98628 16.9823 5.34979 14.0229 2.41427C12.4062 0.79561 10.3236 0 8.2137 0C6.10376 0 4.02122 0.79561 2.40451 2.41427C-0.801504 5.62414 -0.801504 10.8368 2.40451 14.0466C4.02122 15.6653 6.10376 16.4609 8.2137 16.4609C9.96742 16.4609 11.7211 15.9122 13.1734 14.7874L18.1058 19.6708C18.325 19.8903 18.599 20 18.9004 20C19.1744 20 19.4759 19.8903 19.6951 19.6708C20.1061 19.2593 20.1061 18.546 19.6677 18.107ZM8.2411 14.2387C6.62439 14.2387 5.1447 13.6077 3.99382 12.4829C1.66466 10.1509 1.66466 6.33745 3.99382 3.97805C5.11729 2.85322 6.62439 2.22222 8.2411 2.22222C9.85781 2.22222 11.3375 2.85322 12.4884 3.97805C13.6393 5.10288 14.2421 6.6118 14.2421 8.23045C14.2421 9.84911 13.6119 11.3306 12.4884 12.4829C11.3649 13.6351 9.83041 14.2387 8.2411 14.2387Z'
        fill={color}
        opacity={opacity}
      />
    </Svg>
  );
}
