import React, { useEffect, useState } from 'react';
import {
    KeyboardAvoidingView,
    Platform,
    Pressable,
    Text,
    View,
    TextInput
} from 'react-native';
import {
    PhoneNumberFormat as PNF,
    PhoneNumberUtil as PNU,
} from 'google-libphonenumber';
import tw from '../../utils/Tailwind';
import ChevronDownIcon from '../icons/ChevronDownIcon';
import CountryCodeSelect from './CountryCodeSelect';
import * as Localization from 'expo-localization';
import Phone from '../../classes/Formatting/Phone';

const phoneUtil = PNU.getInstance();

export function PhoneInput({
    label,
    placeholder = 'Enter Phone',
    countryCode = 'US',
    dark = true,
    flow = '',
    error = null,
    keyboardType = 'phone-pad',
    autoFocus = false,
    focusPhoneInput = false,
    style = {},
    textStyle = {},
    dropDownStyle = {},
    boxStyle = {},
    labelStyle = {},
    testID = 'phone-input',
    onParse = (phone: string) => {},
    onChangeText = () => {},
    resetPhone = () => {},
    initialPhone = '',
}) {
    const [isCountryCodeSelectOpen, setIsCountryCodeSelectOpen] =
        useState(false);

    const [selectedCountryCode, setSelectedCountryCode] = useState(countryCode);

    const handleToggleCountryCodeSelectOpen = () =>
        setIsCountryCodeSelectOpen(!isCountryCodeSelectOpen);

    const removeNonDigits = (value) => {
        return value.replace(/[^\d+]/gi, '');
    };

    const [phone, setPhone] = useState('');

    const updatePhoneNumber = (value) => {
        value = removeNonDigits(value);

        if (value.length === 0) {
            setPhone('');
            onParse('');
            return;
        }

        resetPhone();

        try {
            const formattedCountryCode = selectedCountryCode;

            let parsed = phoneUtil.parse(value, formattedCountryCode);

            if (!phoneUtil.isValidNumber(parsed)) {
                setPhone(value);
                onParse(value);
                return;
            }

            if (value[0] === '+') {
                let region = phoneUtil.getRegionCodeForNumber(parsed);
                setSelectedCountryCode(region);
            }

            setPhone(phoneUtil.format(parsed, PNF.NATIONAL));
            onParse(phoneUtil.format(parsed, PNF.E164));
        } catch (e) {
            setPhone(value);
            onParse(value);
            return;
        }
    };

    const handleCountryCodeSelect = (code) => {
        setSelectedCountryCode(code === 'UK' ? 'GB' : code);
        setIsCountryCodeSelectOpen(false);
    };

    const getRegionCodes = async () => {
        const locales = await Localization.getLocales();
        const regionCodes = locales.map((locale) => {
            return locale.regionCode;
        });
        return regionCodes;
    };

    useEffect(() => {
        getRegionCodes().then((localeCode) => {
            setIsCountryCodeSelectOpen(false);
        });
    }, []);

    useEffect(() => {
        updatePhoneNumber(initialPhone);
    }, [initialPhone]);

    return (

            <View>
                {label && (
                    <Text
                        style={[
                            tw`label-xsmall text-white mb-2`,
                            labelStyle
                        ]}
                    >
                        {label}
                    </Text>
                )}
                <View>
                    <View
                        style={[
                            tw`flex-row items-center justify-center rounded-lg border border-brand-black ${
                                !dark ? ' rounded' : ' rounded-none'
                            }`,
                            error
                                ? tw`border-brand-red`
                                : dark
                                ? tw`bg-transparent`
                                : tw`border-brand-black`,
                                boxStyle
                        ]}
                    >
                        <View
                            style={[
                                tw`flex-row items-center justify-center`,
                                error
                                    ? true
                                        ? tw`bg-[#491E21]`
                                        : tw`bg-[#de545d]`
                                    : tw`bg-transparent`,
                            ]}
                        >
                            <Pressable
                                onPress={handleToggleCountryCodeSelectOpen}
                                activeOpacity={0.9}
                                style={[
                                    tw`flex-row items-center justify-center`,
                                    dark
                                        ? tw`bg-transparent`
                                        : null,
                                ]}
                            >
                                <View style={tw`mx-2`}>
                                    <ChevronDownIcon
                                        height={14}
                                        width={14}
                                        color={dark ? 'white' : 'black'}
                                    />
                                </View>
                                <Text
                                    style={[
                                        tw`heading-4 `,
                                        dark ? tw`text-white` : tw`text-black`, dropDownStyle
                                    ]}
                                >
                                    {selectedCountryCode === 'GB'
                                        ? 'UK'
                                        : selectedCountryCode}
                                </Text>
                            </Pressable>
                        </View>
                            <TextInput
                                autoComplete={'tel'}
                                textContentType={'telephoneNumber'}
                                onChangeText={updatePhoneNumber}
                                value={phone}
                                containerStyle={tw`flex-1 flex-row items-center justify-center max-w-full `}
                                placeholder={placeholder}
                                keyboardType={keyboardType}
                                returnKeyType={null}
                                isReverseColor={!dark}
                                style={[tw`bg-transparent text-brand-black max-w-full`, {outlineStyle: 'none'}, style]}
                                textStyle={[
                                    tw`flex-row items-center justify-center pl-1 max-w-full`, { outlineStyle: 'none'},
                                    phone?.length
                                        ? tw`leading-snug text-heading-lg`
                                        : tw`heading-2`, textStyle
                                ]}
                                autoFocus={false}
                                focus={focusPhoneInput}
                                keyboardAppearance='dark'
                                testID={testID}
                            />
                    </View>
                    {error && (
                        <Text
                            style={tw`absolute -bottom-7 body mt-2.5 text-[#BE3740] font-sailec-medium`}
                        >
                            {error}
                        </Text>
                    )}
                </View>

                <CountryCodeSelect
                    isOpen={isCountryCodeSelectOpen}
                    onSelect={handleCountryCodeSelect}
                    onDismiss={handleToggleCountryCodeSelectOpen}
                    dark={dark}
                />
            </View>

    );
}
