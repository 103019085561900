import Filterable, {FilterData} from "../Filterable";
import {FilterableRestaurant} from "../FilterableRestaurant";

export default class NeighborhoodFilter implements Filterable {
    filter(restaurants: FilterableRestaurant[], data: FilterData): FilterableRestaurant[] {
        if (! data.neighborhoods.length) {
            return restaurants;
        }

        return restaurants.filter((restaurant: FilterableRestaurant): boolean => {
            if (! restaurant.neighborhood) {
                return false;
            }

            // Filter down to where neighborhood is in FilterDataIds list
            return data.neighborhoods.includes(restaurant.neighborhood.id);
        })
    }
}