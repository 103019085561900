import React, { useEffect, useState } from 'react';
import { View, Text, Pressable, ScrollView } from 'react-native';
import { FilterableRestaurant } from '../../classes/RestaurantFilters/FilterableRestaurant';
import NeighborhoodOptions from '../../classes/RestaurantFilters/DerivableFilterOptions/NeighborhoodOptions';
import CuisineOptions from '../../classes/RestaurantFilters/DerivableFilterOptions/CuisineOptions';
import Filterable, {
    FilterData,
    SimpleFilter,
} from '../../classes/RestaurantFilters/Filterable';
import tw from '../../utils/Tailwind';
import { Area } from '../../gql/graphql';
import { useSearchContext } from '../../contexts/SearchContext';
import PartySizeSelect from '../inputs/PartySizeSelect';
import CustomCalendar from '../inputs/CalendarSingle';
import MapMarkerIcon from '../icons/MapMarkerIcon';
import NeighborhoodIcon from '../icons/NeighborhoodIcon';
import CalendarIcon from '../icons/CalendarIcon';
import PartySizeIcon from '../icons/PartySizeIcon';
import CuisinesIcon from '../icons/CuisinesIcon';

const Filters: React.Component = ({
    restaurants,
    setSingleDate = () => {},
    onClose = () => {},
    areaOptions = [],
}: {
    restaurants: FilterableRestaurant[];
    areaOptions: Area[];
    onClose: Function;
    setSingleDate: Function;
}) => {
    const { filterData, setFilterData } = useSearchContext();

    const [neighborhoodOptions, setNeighborhoodOptions] = useState<
        SimpleFilter[]
    >([]);

    const [cuisineOptions, setCuisineOptions] = useState<SimpleFilter[]>([]);
    const [selectedCuisines, setSelectedCuisines] = useState<number[]>(
        filterData.cuisines
    );

    const [filterDate, setFilterDate] = useState<string>(filterData.start_date);

    const [selectedPartySize, setSelectedPartySize] = useState(
        filterData.party_size
    );

    const [selectedNeighborhoods, setSelectedNeighborhoods] = useState<
        number[]
    >(filterData.neighborhoods);

    const toggleFilterValue = (previous: number[], current: number) => {
        if (previous.includes(current)) {
            return previous.filter((n: number) => n !== current);
        }

        return [...previous, current];
    };

    useEffect(() => {
        if (!areaOptions.length || !filterData.area) return;
    }, [filterData.area]);

    useEffect(() => {
        const n = new NeighborhoodOptions();
        const c = new CuisineOptions();

        setNeighborhoodOptions(n.options(restaurants));
        setCuisineOptions(c.options(restaurants));
    }, [restaurants]);

    return (
        <View style={tw`flex-1 bg-brand-white`}>
            <ScrollView style={tw`relative px-10 pt-6`}>
                <View style={tw`border-b border-brand-altGray py-6`}>
                    <View
                        style={tw`flex flex-row items-center justify-start mb-6`}
                    >
                        <MapMarkerIcon
                            color={tw.color('transparent')}
                            stroke={tw.color('brand-black')}
                            height={16}
                            width={16}
                            style={tw`mr-2`}
                        />
                        <Text style={tw`heading-4`}>Location</Text>
                    </View>
                    <View style={tw`flex flex-row flex-wrap`}>
                        {areaOptions.map((option: Area, index) => {
                            return (
                                <Pressable
                                    onPress={() => {
                                        setSelectedCuisines([]);
                                        setSelectedNeighborhoods([]);
                                        setFilterData((prev) => {
                                            return {
                                                ...prev,
                                                area: parseInt(option.id),
                                            };
                                        });
                                    }}
                                    style={[
                                        tw`mr-2 bg-brand-lightGray py-3 px-4 mb-3 rounded-xl`,
                                        filterData.area === parseInt(option.id)
                                            ? tw`bg-brand-black`
                                            : tw``,
                                    ]}
                                    key={index}
                                >
                                    <Text
                                        style={[
                                            tw`label-xxsmall`,
                                            filterData.area ===
                                            parseInt(option.id)
                                                ? tw`text-white`
                                                : tw``,
                                        ]}
                                    >
                                        {option.name}
                                    </Text>
                                </Pressable>
                            );
                        })}
                    </View>
                </View>

                {neighborhoodOptions.length > 0 && (
                    <View style={tw`border-b border-brand-altGray py-6`}>
                        <View
                            style={tw`flex flex-row items-center justify-start mb-6`}
                        >
                            <NeighborhoodIcon
                                color={tw.color('brand-black')}
                                stroke={tw.color('brand-black')}
                                height={16}
                                style={tw`mr-2`}
                            />
                            <Text style={tw`heading-4 text-brand-black`}>
                                Neighborhood
                            </Text>
                        </View>

                        <View style={tw`flex flex-row flex-wrap`}>
                            {neighborhoodOptions.map(
                                (option: SimpleFilter, index) => {
                                    return (
                                        <Pressable
                                            onPress={() => {
                                                setSelectedNeighborhoods(
                                                    (prev: number[]) =>
                                                        toggleFilterValue(
                                                            prev,
                                                            option.id
                                                        )
                                                );
                                            }}
                                            style={[
                                                tw`mr-2 bg-brand-lightGray py-3 px-4 mb-3 rounded-xl`,
                                                selectedNeighborhoods.includes(
                                                    option.id
                                                )
                                                    ? tw`bg-brand-black`
                                                    : tw``,
                                            ]}
                                            key={index}
                                        >
                                            <Text
                                                style={[
                                                    tw`label-xxsmall`,
                                                    selectedNeighborhoods.includes(
                                                        option.id
                                                    )
                                                        ? tw`text-white`
                                                        : tw``,
                                                ]}
                                            >
                                                {option.name}
                                            </Text>
                                        </Pressable>
                                    );
                                }
                            )}
                        </View>
                    </View>
                )}

                <View style={tw`border-b border-brand-altGray py-6`}>
                    <View style={tw`flex flex-row items-center justify-start`}>
                        <CalendarIcon
                            height={19}
                            width={19}
                            style={tw`mr-2 `}
                        />
                        <Text style={tw`heading-4 text-brand-black`}>Date</Text>
                    </View>
                    <CustomCalendar
                        onSelect={(day) => {
                            setFilterDate(day.dateString);
                        }}
                        selected={filterDate}
                    />
                </View>

                <View
                    style={tw`border-b border-brand-altGray flex-row items-center justify-start py-6`}
                >
                    <View style={tw`flex-row items-center`}>
                        <PartySizeIcon height={16} style={tw`mr-2`} />
                    </View>
                    <View style={tw`flex-1`}>
                        <PartySizeSelect
                            onPartyChange={setSelectedPartySize}
                            allowAnyIgnoreMinSize={true}
                            minPartySize={1}
                            partySize={selectedPartySize}
                        />
                    </View>
                </View>

                {cuisineOptions.length > 0 && (
                    <View style={tw`my-6`}>
                        <View
                            style={tw`flex flex-row items-center justify-start mb-6`}
                        >
                            <CuisinesIcon
                                color={tw.color('brand-black')}
                                height={17}
                                width={17}
                                style={tw`mr-2 `}
                            />
                            <Text style={tw`heading-4`}>Cuisines</Text>
                        </View>
                        <View style={tw`flex flex-row flex-wrap`}>
                            {cuisineOptions.map(
                                (option: SimpleFilter, index) => {
                                    return (
                                        <Pressable
                                            onPress={() => {
                                                setSelectedCuisines(
                                                    (prev: number[]) =>
                                                        toggleFilterValue(
                                                            prev,
                                                            option.id
                                                        )
                                                );
                                            }}
                                            style={[
                                                tw`mr-2 bg-brand-lightGray py-3 px-4 mb-3 rounded-xl`,
                                                selectedCuisines.includes(
                                                    option.id
                                                )
                                                    ? tw`bg-brand-black`
                                                    : tw``,
                                            ]}
                                            key={index}
                                        >
                                            <Text
                                                style={[
                                                    tw`label-xxsmall`,
                                                    selectedCuisines.includes(
                                                        option.id
                                                    )
                                                        ? tw`text-white`
                                                        : tw``,
                                                ]}
                                            >
                                                {option.name}
                                            </Text>
                                        </Pressable>
                                    );
                                }
                            )}
                        </View>
                    </View>
                )}
            </ScrollView>
            <View
                style={tw`px-10 w-100% flex flex-row justify-between items-center mt-6`}
            >
                <Pressable
                    onPress={() => {
                        setSelectedCuisines([]);
                        setSelectedNeighborhoods([]);
                        setSelectedPartySize(filterData.party_size);
                        setSingleDate(false);
                        /** If we want to clear the filters that have been saved to the SearchContext, we can uncomment */
                        // setFilterData((prev) => {
                        //     return {...prev, cuisines: [], neighborhoods: [], party_size: prev.party_size}
                        // })
                    }}
                >
                    <Text style={tw`label-small underline`}>Clear Filters</Text>
                </Pressable>

                <Pressable
                    onPress={() => {
                        if (filterDate !== filterData.start_date) {
                            setSingleDate(true);
                        }
                        setFilterData((prev) => {
                            return {
                                ...prev,
                                cuisines: selectedCuisines,
                                neighborhoods: selectedNeighborhoods,
                                party_size: parseInt(selectedPartySize),
                                start_date: filterDate,
                                end_date: prev.start_date === filterDate ? prev.end_date : null,
                            };
                        });
                        onClose();
                    }}
                    style={tw`bg-brand-altPurple py-3 px-4 rounded-xl`}
                >
                    <Text style={tw`label-small text-white bold`}>
                        Show Results
                    </Text>
                </Pressable>
            </View>
        </View>
    );
};

export default Filters;
