import React, { useEffect, useState } from 'react';
import { Animated, View } from 'react-native';
import tw from '../../utils/Tailwind';
import Svg, { Defs, LinearGradient, Rect, Stop } from 'react-native-svg';

const Shimmer = (style = {}) => {
    const [fadeAnim] = useState(new Animated.Value(0.3));
    const [positionAnim] = useState(new Animated.Value(0));

    const startAnimation = () => {
        const animationInterval = setInterval(() => {
            animation();
        }, 850);

        return () => {
            clearInterval(animationInterval);
        };
    };

    const animation = () => {
        Animated.timing(fadeAnim, {
            toValue: 0.1,
            duration: 400,
            useNativeDriver: false,
        }).start();
        Animated.timing(positionAnim, {
            toValue: -1,
            duration: 400,
            useNativeDriver: false,
        }).start();

        setTimeout(() => {
            Animated.timing(fadeAnim, {
                toValue: 0.3,
                duration: 400,
                useNativeDriver: false,
            }).start();

            Animated.timing(positionAnim, {
                toValue: 0,
                duration: 400,
                useNativeDriver: false,
            }).start();
        }, 400);
    };

    useEffect(() => {
        startAnimation();
    }, []);

    return (
        <View style={[tw`h-full w-full overflow-hidden relative`, style]}>
            <Animated.View
                style={[
                    tw`h-full w-full overflow-hidden`,
                    {
                        opacity: fadeAnim,
                        left: positionAnim.interpolate({
                            inputRange: [0, 0],
                            outputRange: [0, 500],
                        }),
                    },
                ]}
            >
                <Svg width={'150%'} height={'100%'} style={tw`top-0`}>
                    <Rect
                        width='100%'
                        height='100%'
                        fill='url(#paint1_linear_10274_41590)'
                    />
                    <Defs>
                        <LinearGradient
                            id='paint1_linear_10274_41590'
                            x1='0'
                            y1='0'
                            x2='195'
                            y2='20'
                            gradientUnits='userSpaceOnUse'
                        >
                            <Stop stopColor='gray' />
                            <Stop
                                offset='1'
                                stopColor='#a4a1c2'
                                stopOpacity='0'
                            />
                            <Stop offset='1' stopColor='gray' stopOpacity='0' />
                        </LinearGradient>
                    </Defs>
                </Svg>
            </Animated.View>
        </View>
    );
};

export default Shimmer;
