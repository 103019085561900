import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function CopyIcon({
    style = {},
    color = 'black',
    stroke = 'black',
    strokeWidth = 0.5,
    height = 20,
    width = 20,
}) {
    return (
        <Svg
            style={style}
            width={width}
            height={height}
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
        >
            <Path
                d="M16 13C16.5312 13 17 12.5625 17 12V5.625C17 5.5 16.9375 5.375 16.8438 5.28125L14.7188 3.15625C14.625 3.0625 14.5 3 14.375 3H11C10.4375 3 10 3.46875 10 4V12C10 12.5625 10.4375 13 11 13H16ZM17.5312 4.5625C17.8125 4.84375 18 5.25 18 5.625V12C18 13.125 17.0938 14 16 14H11C9.875 14 9 13.125 9 12V4C9 2.90625 9.875 2 11 2H14.375C14.75 2 15.1562 2.1875 15.4375 2.46875L17.5312 4.5625ZM4 6H8V7H4C3.4375 7 3 7.46875 3 8V16C3 16.5625 3.4375 17 4 17H9C9.53125 17 10 16.5625 10 16V15H11V16C11 17.125 10.0938 18 9 18H4C2.875 18 2 17.125 2 16V8C2 6.90625 2.875 6 4 6Z" 
               fill={color}
            />
        </Svg>
    );
}
