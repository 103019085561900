import React from 'react';
import Svg, {Path} from "react-native-svg";

const FilterIcon = () => {
    return <Svg fill="none" height={24} width={24} viewBox="0 0 24 24">
        <Path fill="#0D0D0D"
              d="M4.094 5.719A1.286 1.286 0 0 1 5.25 5h13.5c.469 0 .906.281 1.125.719.188.437.125.968-.188 1.343L14 14.032V18c0 .406-.219.75-.563.906-.343.157-.75.125-1.062-.093l-2-1.5c-.25-.188-.375-.47-.375-.813v-2.469L4.281 7.062a1.312 1.312 0 0 1-.187-1.343Z"/>
    </Svg>
}

export default FilterIcon;
