import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function DorsiaLogo({color = 'white', width = 103, height = 15, scale = 1}) {
  return (
    <Svg
      width={width * scale}
      height={height * scale}
      viewBox='0 0 103 15'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <Path
        d='M0 0.19V14.402H5.643C9.614 14.402 12.616 11.78 12.616 7.296C12.616 2.812 9.652 0.19 5.681 0.19H0ZM5.681 2.128C8.455 2.128 10.355 3.895 10.355 7.296C10.355 10.697 8.417 12.464 5.662 12.464H2.242V2.128H5.681Z'
        fill={color}
      />
      <Path
        d='M27.3599 0C23.2179 0 20.1019 2.793 20.1019 7.296C20.1019 11.799 23.2179 14.592 27.3599 14.592C31.4829 14.592 34.5989 11.799 34.5989 7.296C34.5989 2.793 31.4829 0 27.3599 0ZM27.3599 2.014C30.1909 2.014 32.2999 3.971 32.2999 7.296C32.2999 10.602 30.1909 12.578 27.3599 12.578C24.5099 12.578 22.4009 10.602 22.4009 7.296C22.4009 3.971 24.5099 2.014 27.3599 2.014Z'
        fill={color}
      />
      <Path
        d='M50.4909 8.588C51.9729 7.98 52.9419 6.631 52.9419 4.579C52.9419 1.71 51.0419 0.19 48.4769 0.19H42.7389V14.402H44.9999V8.968H48.1349L51.1369 14.402H53.6829L50.4909 8.588ZM44.9809 2.185H48.1919C49.5409 2.185 50.6239 2.964 50.6239 4.579C50.6239 6.175 49.5409 6.954 48.1919 6.954H44.9809V2.185Z'
        fill={color}
      />
      <Path
        d='M65.8953 0C62.5893 0 60.9743 1.919 60.9743 4.161C60.9743 6.878 63.0643 7.657 65.6103 8.284C67.7763 8.797 69.0113 9.12 69.0113 10.545C69.0113 12.027 67.8143 12.635 66.1803 12.635C64.3943 12.635 63.0453 11.666 62.9503 10.146L60.6513 10.279C60.7463 13.053 63.0643 14.592 66.1803 14.592C68.9923 14.592 71.3103 13.319 71.3103 10.393C71.3103 7.885 69.4293 6.973 66.4463 6.251C64.3943 5.776 63.2923 5.51 63.2923 4.085C63.2923 2.869 64.2043 1.957 65.9333 1.957C67.5293 1.957 68.5743 2.831 68.6503 4.256L70.9493 4.123C70.8543 1.52 68.8023 0 65.8953 0Z'
        fill={color}
      />
      <Path d='M79.3918 0.19V14.402H81.6528V0.19H79.3918Z' fill={color} />
      <Path
        d='M94.8674 0.19L88.8634 14.402H91.3144L92.6254 11.229H99.0474L100.339 14.402H102.809L96.7864 0.19H94.8674ZM98.1924 9.215H93.4994L95.8554 3.496L98.1924 9.215Z'
        fill={color}
      />
    </Svg>
  );
}
