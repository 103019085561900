export default {
  'width-limit-wide': 'sm:w-full desktop:w-300 desktop:max-w-300',
  'width-limit-medium': 'sm:w-full sm:max-w-[90%] desktop:w-225 desktop:max-w-225',
  'width-limit-short': 'sm:w-full sm:max-w-[90%] desktop:w-175 desktop:max-w-175',
  title: 'font-sailec text-[64px] leading-[70px]',
  'heading-1': 'font-sailec text-[28px] leading-normal',
  'heading-2': 'font-sailec text-[26px] leading-normal',
  'heading-2.5': 'font-sailec-medium text-[24px] leading-normal',
  'heading-3': 'font-sailec text-[22px] leading-normal',
  'heading-4': 'font-sailec text-[18px] leading-normal',
  'label-large': 'font-sailec text-[17px] leading-normal',
  body: 'font-sailec text-[16px] leading-normal',
  'text-body': 'font-sailec text-[16px] leading-normal',
  'text-body-md': 'font-sailec text-[17px] leading-normal',
  'label-medium': 'font-sailec text-[18px] leading-normal',
  'label-small': 'font-sailec text-[15px] leading-normal',
  'label-xsmall': 'font-sailec text-[14px] leading-normal',
  'label-xxsmall': 'font-sailec text-[13px] leading-normal',
  'label-xxxsmall': 'font-sailec text-[11px] leading-normal',
  'button-small': 'font-sailec-medium text-[18px] leading-normal',
  'button-large': 'font-sailec-medium text-[23px] leading-normal',
  'button-white':
    'font-sailec-medium bg-brand-altWhite p-4 rounded-xl shadow-md',
  'button-black':
    'font-sailec-medium bg-brand-altBlack p-4 rounded-xl shadow-md',
  'heading-2-medium': 'font-sailec-medium text-[26px] leading-normal',
  'label-small-bold': 'font-sailec-medium text-[15px] leading-normal',
  'qa-question': 'font-sailec-medium text-[18px] leading-normal',
  'qa-answer': 'font-sailec text-[18px] leading-normal',
  bold: 'font-sailec-medium',
  bolder: 'font-sailec-medium font-black',
  'shadow-button': {
    boxShadow: '#000000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.08,
    shadowRadius: 6,
    elevation: 0,
  },
  'shadow-text': {
    textShadowColor: 'rgba(13, 10, 6, 0.70)',
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 15,
  },
  'shadow-block': {
    borderColor: 'rgba(13, 10, 6, 0.05)',
    borderWidth: 1,
    shadowColor: 'black',
    shadowOpacity: 0.08,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 15,
  },
};