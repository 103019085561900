import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function CloseIcon({
  color = 'black',
  width = 16,
  height = 16,
  stroke = 1,
}) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox={'0 0 16 16'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <Path d='M1 1L15 15' stroke={color} strokeWidth={stroke} />
      <Path d='M15 1L1 15' stroke={color} strokeWidth={stroke} />
    </Svg>
  );
}
