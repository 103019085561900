import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function PartySizeIcon({
    style = {},
    color = 'black',
    height = 14,
    width = 15,
}) {
    return (
        <Svg
            style={style}
            width={width}
            height={height}
            viewBox='0 0 14 15'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.5776 8.78442C7.97188 8.78442 11.1552 9.05404 11.1552 11.3866C11.1552 13.8437 7.44594 13.9912 5.85486 14L5.27719 14C3.58072 13.9912 0 13.8446 0 11.4013C0 8.94143 3.70926 8.79384 5.30033 8.78499L5.49149 8.78444C5.52128 8.78442 5.55 8.78442 5.5776 8.78442ZM5.5776 9.88642C3.53596 9.88642 1.102 10.1487 1.102 11.4013C1.102 12.3946 2.60807 12.8986 5.5776 12.8986C8.54712 12.8986 10.0532 12.3894 10.0532 11.3866C10.0532 10.3911 8.54712 9.88642 5.5776 9.88642ZM12.2739 8.44126C14.2619 8.7388 14.6784 9.65934 14.6784 10.3786C14.6784 10.9141 14.447 11.6393 13.3428 12.0573C13.2789 12.0815 13.2128 12.0933 13.1474 12.0933C12.9248 12.0933 12.7154 11.9574 12.6324 11.7377C12.5244 11.4534 12.6677 11.1346 12.952 11.0273C13.5764 10.7907 13.5764 10.502 13.5764 10.3786C13.5764 9.96202 13.0835 9.67697 12.1115 9.53224C11.8103 9.48669 11.6024 9.20605 11.6472 8.90557C11.6928 8.60435 11.9785 8.40232 12.2739 8.44126ZM5.5776 0C7.65744 0 9.35015 1.69267 9.35015 3.77252C9.35452 4.77461 8.96588 5.72086 8.25766 6.43348C7.55091 7.14685 6.60687 7.54136 5.60258 7.54504H5.5776C3.49702 7.54504 1.80434 5.85236 1.80434 3.77252C1.80434 1.69267 3.49702 0 5.5776 0ZM10.7846 0.82562C12.2282 1.06218 13.2759 2.29716 13.2759 3.76135C13.2729 5.23436 12.1732 6.49946 10.717 6.7037C10.6913 6.70737 10.6656 6.70884 10.6399 6.70884C10.3695 6.70884 10.1337 6.50974 10.0948 6.23424C10.0529 5.93303 10.2623 5.65386 10.5635 5.61198C11.4804 5.48341 12.1724 4.68777 12.1739 3.76062C12.1739 2.83934 11.5142 2.06207 10.6068 1.91366C10.3064 1.8637 10.1029 1.58086 10.1521 1.28038C10.2013 0.9799 10.4856 0.777866 10.7846 0.82562ZM5.5776 1.102C4.10459 1.102 2.90635 2.30024 2.90635 3.77252C2.90635 5.24479 4.10459 6.44304 5.5776 6.44304H5.60037C6.30859 6.4401 6.97567 6.16166 7.47598 5.65694C7.97702 5.15369 8.25105 4.48441 8.24814 3.77472C8.24814 2.30024 7.04987 1.102 5.5776 1.102Z'
                fill={color}
            />
        </Svg>
    );
}
