import {
    SafeAreaView,
    ScrollView,
    Text,
    View,
    Modal,
    Pressable,
} from 'react-native';
import React, { useEffect, useState } from 'react';
import tw from '../utils/Tailwind';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import RestaurantList from '../components/lists/RestaurantList';
import HttpClient from '../utils/HttpClient';
import { useAuthContext } from '../contexts/AuthContext';
import Filters from '../components/RestaurantSearch/Filters';
import CloseIcon from '../components/icons/CloseIcon';
import { useSearchContext } from '../contexts/SearchContext';
import CuisineFilter from '../classes/RestaurantFilters/Filterables/CuisineFilter';
import NeighborhoodFilter from '../classes/RestaurantFilters/Filterables/NeighborhoodFilter';
import Header from '../components/layout/ConciergeHeader';
import Dorsia from '../components/icons/Logos/Dorsia';
import RestaurantSearchField from '../components/RestaurantSearch/SearchField';
import { format, addDays, isBefore } from 'date-fns';
import FilterIcon from '../components/icons/FilterIcon';
import DorsiaLogo from '../components/icons/Logos/Dorsia';
import MapMarkerIcon from '../components/icons/MapMarkerIcon';
import { Restaurants } from '../queries/Restaurants';
import { Areas } from '../queries/Areas';
import {
    RequiredDocuments,
    AcceptDocumentVersion,
} from '../queries/RequiredDocuments';
import {
    Area,
    AreasQuery,
    Restaurant,
    RequiredDocumentsDocument,
    AcceptDocumentVersionMutation,
} from '../gql/graphql';
import { useIsFocused } from '@react-navigation/native';
import Checkbox from '../components/inputs/Checkbox';
import BasicButton from '../components/inputs/BasicButton';
import Markdown from 'react-native-markdown-display';

const HomeScreen = ({ navigation }: NativeStackScreenProps<any>) => {
    const { filterData, setFilterData } = useSearchContext();
    const { currentUser } = useAuthContext();
    const [singleDate, setSingleDate] = useState(false);
    const [areaName, setAreaName] = useState(null);
    const [loadingRestaurants, setLoadingRestaurants] = useState(true);
    const isFocused = useIsFocused();
    const [requiredDocuments, setRequiredDocuments] = useState<
        typeof RequiredDocuments | []
    >([]);
    const [requestedDocumentsCheckbox, setRequestedDocumentsCheckbox] =
        useState<Boolean>(false);

    const handleApproveDocument = (docId) => {
        HttpClient.graphqlQuery(AcceptDocumentVersion, { id: docId })
            .then((data: AcceptDocumentVersionMutation) => {
                setRequestedDocumentsCheckbox(false);
                requiredDocuments.shift();
                setRequiredDocuments(requiredDocuments);
            })
            .catch((err) => {});
    };

    const fetchRequiredDocuments = () => {
        return HttpClient.graphqlQuery(RequiredDocuments)
            .then(({ requiredDocuments }: typeof RequiredDocumentsDocument) => {
                setRequiredDocuments(requiredDocuments);
            })
            .catch((err) => {});
    };

    const updateFeedData = () => {
        setLoadingRestaurants(true);
        HttpClient.graphqlQuery(Restaurants, {
            area_id: filterData.area,
            party_size: filterData.party_size,
            start_date: filterData.start_date,
            end_date: singleDate ? null : filterData.end_date,
        })
            .then(
                ({
                    restaurantsByArea,
                }: {
                    restaurantsByArea: Restaurant[];
                }) => {
                    setRestaurants(restaurantsByArea);
                }
            )
            .finally(() => {
                setLoadingRestaurants(false);
            });
    };

    useEffect(() => {
        if (isFocused) {
            fetchRequiredDocuments();
            updateFeedData();
        }
    }, [isFocused]);

    useEffect(() => {
        if (singleDate) {
            setFilterData((prev) => {
                return {
                    ...prev,
                    end_date: null,
                };
            });
        }
    }, [singleDate]);
    
    useEffect(() => {
        updateFeedData();
    }, [filterData.area, filterData.party_size, filterData.start_date]);

    const formattedStartDate = () => {
        const baseDate = new Date(filterData.start_date);
        const baseDateOnly = new Date(baseDate.valueOf() + baseDate.getTimezoneOffset() * 60 * 1000);
        const formattedDate = format(baseDateOnly, 'E, LLL do');

        if (filterData.end_date) return null;
        if (formattedDate) return formattedDate;
        return null;
    }

    const [areaOptions, setAreaOptions] = useState<Area[]>([]);

    useEffect(() => {
        if (filterData.area) {
            const area = areaOptions.find((area) => filterData.area == area.id);
            setAreaName(area?.name);
        }
    }, [filterData.area, areaOptions]);

    useEffect(() => {

        const sevenDaysFromStartDate = format(
            addDays(filterData.start_date, 7),
            'yyyy-MM-dd'
        );

        setFilterData((prev) => {
            return {
                ...prev,
                end_date: sevenDaysFromStartDate,
            };
        });

        HttpClient.graphqlQuery(Areas).then((data: AreasQuery) => {
            setAreaOptions(data.areas?.data ?? []);
        });

        const refreshInterval = setInterval(() => {
            isBefore(new Date(filterData.start_date), new Date())
                ? setFilterData((prev) => {
                      return {
                          ...prev,
                          start_date: format(new Date(), 'yyyy-MM-dd'),
                      };
                  })
                : console;
            updateFeedData();
        }, 300000);
        return () => clearInterval(refreshInterval); //This is important
    }, []);

    const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
    const [filteredRestaurants, setFilteredRestaurants] = useState<
        Restaurant[]
    >([]);

    useEffect(() => {
        const c = new CuisineFilter();
        const n = new NeighborhoodFilter();

        setFilteredRestaurants(() => {
            return c.filter(n.filter(restaurants, filterData), filterData);
        });
    }, [restaurants, filterData.cuisines, filterData.neighborhoods]);

    const [showRestaurantFilters, setShowRestaurantFilters] = useState(false);
    const activeFilters =
        (filterData.cuisines.length > 0 ? 1 : 0) +
        (filterData.neighborhoods.length > 0 ? 1 : 0) +
        (filterData.party_size > 0 ? 1 : 0);

    const renderCuisinesHomeFilter = () => {
        return (
            <View
                style={tw`flex flex-row items-center sm:justify-center lg:justify-end mt-4 flex-wrap`}
            >
                <View style={tw`sm:hidden lg:flex flex-1 pl-2`}>
                    <Pressable
                        onPress={() => setShowRestaurantFilters(true)}
                        style={tw`flex-row items-center justify-start flex-grow pr-2 pb-1`}
                    >
                        <MapMarkerIcon
                            strokeWidth={1}
                            style={tw`mr-2 opacity-50`}
                            height={18}
                            width={18}
                        />
                        <Text
                            style={tw`label-small bold text-brand-black opacity-50`}
                        >
                            {areaName}
                        </Text>
                        {formattedStartDate(filterData?.start_date) && (
                            <Text
                                style={tw`ml-4 label-small bold text-brand-black opacity-50`}
                            >
                                 {formattedStartDate(filterData?.start_date)}
                            </Text>
                        )}
                    </Pressable>
                </View>
                <View style={tw`flex-1`}>
                    <RestaurantSearchField
                        onRestaurantPress={(restaurant: Restaurant) => {
                            navigation.navigate('Restaurant', {
                                id: restaurant.id,
                            });
                        }}
                        style={tw`sm:pr-4 lg:hidden max-w-full -mt-6`}
                    />
                </View>

                <View style={tw`flex`}>
                    <Pressable
                        onPress={() => setShowRestaurantFilters(true)}
                        style={tw`px-4 py-2 rounded-lg bg-brand-lightGray flex-shrink flex-row items-center `}
                    >
                        <Text style={tw`text-black bold mr-3`}>Filters</Text>

                        <View>
                            <FilterIcon />
                        </View>

                        {activeFilters > 0 && (
                            <View
                                style={tw`bg-brand-black rounded-full h-5 w-5 flex items-center justify-center -mt-10 -mr-5`}
                            >
                                <Text
                                    style={tw`text-brand-white p-0 m-0 text-sm font-bold`}
                                >
                                    {activeFilters}
                                </Text>
                            </View>
                        )}
                    </Pressable>
                </View>
            </View>
        );
    };

    if (!currentUser) {
        return (
            <View
                style={tw`h-full w-full flex flex-col items-center justify-center`}
            >
                <View>
                    <DorsiaLogo
                        scale={3}
                        color={tw.color('brand-black opacity-25')}
                    />
                </View>
            </View>
        );
    }

    return (
        <SafeAreaView style={tw`flex-1`}>
            <View
                style={tw`w-full border-b border-brand-lightGray bg-brand-white `}
            >
                <Header
                    topChild={
                        <RestaurantSearchField
                            onRestaurantPress={(restaurant) => {
                                navigation.navigate('Restaurant', {
                                    id: restaurant.id,
                                });
                            }}
                            style={tw`hidden lg:flex`}
                        />
                    }
                    bottomChild={renderCuisinesHomeFilter()}
                    conciergeUser={currentUser ? currentUser : null}
                />
            </View>
            {filteredRestaurants.length > 0 && (
                <RestaurantList
                    restaurants={filteredRestaurants}
                    singleDateAvailability={singleDate}
                    onRestaurantPress={(restaurant: Restaurant) => {
                        navigation.navigate('Restaurant', {
                            id: restaurant.id,
                        });
                    }}
                    onAvailabilityPress={(
                        restaurant: Restaurant,
                        availability = null
                    ) => {

                        if (!singleDate) {
                            navigation.navigate('Restaurant', {
                                availability_date: availability?.date,
                                id: restaurant.id,
                            });
                            return;
                        }

                        navigation.navigate('Restaurant', {
                            availability_date: availability?.date,
                            availability_time: availability?.time,
                            id: restaurant.id,
                        });
                    }}
                />
            )}
            {filteredRestaurants.length == 0 &&
                !loadingRestaurants &&
                currentUser && (
                    <View
                        style={tw`flex-1 items-center justify-center p-8 bg-brand-white `}
                    >
                        <Text
                            style={tw`heading-1 text-center sm:text-[30px] lg:text-[45px] bold text-brand-black`}
                        >
                            No restaurants match this criteria.
                        </Text>
                        <Text
                            style={tw`heading-3 text-center sm:text-[16px] lg:text-[18px] text-brand-black mt-4 leading-5`}
                        >
                            Try adjusting your filters or searching for
                            something else.
                        </Text>
                    </View>
                )}

            {showRestaurantFilters && (
                <View
                    style={tw`absolute w-screen h-screen bg-brand-black bg-opacity-70 z-20`}
                ></View>
            )}
            <Modal
                animationType='slide'
                transparent={true}
                visible={showRestaurantFilters}
                style={tw`h-screen max-h-screen`}
            >
                <View
                    style={tw`sm:w-full lg:max-w-125 mx-auto bg-brand-white h-[95%] rounded-xl shadow-xl my-8 pb-6`}
                >
                    <View style={tw`border-b border-brand-lightGray`}>
                        <Text style={tw`heading-4 bolder text-center py-4`}>
                            Filters
                        </Text>
                        <Pressable
                            accessible={true}
                            accessibilityLabel={'Close Button'}
                            onPress={() => setShowRestaurantFilters(false)}
                            style={[
                                tw`absolute top-2 right-2 p-4`,
                                { outlineStyle: 'none' },
                            ]}
                            testID={'button-test-id'}
                        >
                            <CloseIcon />
                        </Pressable>
                    </View>
                    <Filters
                        restaurants={restaurants}
                        setSingleDate={setSingleDate}
                        areaOptions={areaOptions}
                        onClose={() => {
                            setLoadingRestaurants(true);
                            setShowRestaurantFilters(false);
                            setRestaurants([]);
                            updateFeedData();
                        }}
                    />
                </View>
            </Modal>

            <Modal
                animationType='slide'
                transparent={true}
                visible={requiredDocuments.length > 0}
                style={tw`h-screen h-screen`}
            >
                <ScrollView style={tw`w-full h-full bg-brand-white pb-40`}>
                    <View style={tw`border-b border-brand-lightGray`}>
                        <Text
                            style={tw`heading-4 bolder text-left pt-8 pb-10 pl-8`}
                        >
                            <Dorsia
                                scale={1.5}
                                color={tw.color(`brand-black`)}
                            />
                        </Text>
                    </View>
                    <View
                        style={tw`sm:w-full lg:max-w-200 mx-auto my-8 pb-16 px-8 sm:h-150 lg:h-200`}
                    >
                        <Text style={tw`heading-2 bold text-left py-4`}>
                            Welcome to Dorsia
                        </Text>
                        <Text style={tw`heading-4 text-left pb-4`}>
                            {requiredDocuments[0]?.document?.title}
                        </Text>
                        <ScrollView
                            style={tw`sm:w-full lg:max-w-190 mx-auto my-8 pb-6 border border-brand-lightGray bg-brand-lightGray bg-opacity-20 sm:min-h-[70%] lg:min-h-[45%] p-4`}
                            showsVerticalScrollIndicator={true}
                        >
                            <Markdown>
                                {requiredDocuments[0]?.markdown}
                            </Markdown>
                        </ScrollView>

                        <View
                            style={tw`flex-row justify-between items-center w-full`}
                        >
                            <Checkbox
                                testID='requested-documents-checkbox'
                                onChange={() => {
                                    setRequestedDocumentsCheckbox(
                                        !requestedDocumentsCheckbox
                                    );
                                }}
                                checked={requestedDocumentsCheckbox}
                                style={tw`flex-1`}
                                value={requestedDocumentsCheckbox}
                            >
                                <Text style={tw`px-2`}>
                                    I agree to the{' '}
                                    {requiredDocuments[0]?.document?.title}{' '}
                                    outlined above.
                                </Text>
                            </Checkbox>
                        </View>
                        <View style={tw`mt-8`}>
                            <BasicButton
                                onPress={() =>
                                    handleApproveDocument(
                                        requiredDocuments[0]?.id
                                    )
                                }
                                disabled={!requestedDocumentsCheckbox}
                                style={tw`bg-brand-black py-4 px-8 rounded-xl`}
                                disabledStyle={tw`bg-brand-black py-4 px-8 rounded-xl opacity-50`}
                                justText='Submit'
                            />
                        </View>
                    </View>
                </ScrollView>
            </Modal>
        </SafeAreaView>
    );
};

export default HomeScreen;
