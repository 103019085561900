import React, { useEffect, useState } from 'react';
import { Pressable, View, Text } from 'react-native';
import { Calendar as WixCalendar, LocaleConfig } from 'react-native-calendars';
import tw from '../../utils/Tailwind';
import moment from 'moment';
import ChevronLeftIcon from '../icons/ChevronLeftIcon';
import ChevronRightIcon from '../icons/ChevronRightIcon';
// import { useAnalytics } from '@segment/analytics-react-native';
// import { useConfigContext } from '../providers/ConfigContext';

// const analyticsProps = {
//     component: 'Custom Calendar',
//     flow: 'Filters',
// };

// export function isBefore(firstDate, secondDate, period = 'day') {
//     return moment(firstDate).isBefore(secondDate, period);
// }

export default function CustomCalendar({ onSelect, selected, minDate, loading = false }) {
    // const { track } = useAnalytics();
    // const { maxDaysInAdvance } = useConfigContext();
    const maxDaysInAdvance = 30;
    const today = moment();
    const maxDate = moment().add(maxDaysInAdvance, 'days');

    const [viewingMonth, setViewingMonth] = useState('');
    const [selectedDate, setSelectedDate] = useState(selected);
    const [disableLeftArrow, setDisableLeftArrow] = useState(true);
    const [disableRightArrow, setDisableRightArrow] = useState(false);

    const handleDayPress = (day) => {
        if (loading) return;
        // track('Calendar Date Pressed', {
        //     ...analyticsProps,
        //     date: day.dateString,
        // });
        setSelectedDate(day.dateString);
        onSelect(day);
    };

    const changeViewingMonth = (change) => {
        // track('Month Changed', { ...analyticsProps, month: change.month });
        setViewingMonth(change.month);
    };

    useEffect(() => {
        setViewingMonth(moment(selectedDate).format('M'));
    }, [selectedDate, selected]);

    useEffect(() => {
        if (viewingMonth == today.format('M')) {
            setDisableLeftArrow(true);
            setDisableRightArrow(false);
        } else if (viewingMonth == maxDate.format('M')) {
            setDisableLeftArrow(false);
            setDisableRightArrow(true);
        } else {
            setDisableLeftArrow(false);
            setDisableRightArrow(false);
        }
    }, [viewingMonth, maxDate]);

    /*
    the only way to override a single property like dayNamesShort is to rewrite the entire locale
    https://github.com/wix/react-native-calendars/issues/1119
  */
    LocaleConfig.locales['en'] = {
        monthNames: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
        monthNamesShort: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sept',
            'Oct',
            'Nov',
            'Dec',
        ],
        dayNames: [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
        ],
        dayNamesShort: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        today: 'today',
    };
    LocaleConfig.defaultLocale = 'en';

    return (
        <WixCalendar
            current={selected}
            minDate={minDate ?? today.format('YYYY-MM-DD')}
            maxDate={maxDate.format('YYYY-MM-DD')}
            style={tw`bg-transparent rounded-xl`}
            onMonthChange={changeViewingMonth}
            hideExtraDays
            disableAllTouchEventsForDisabledDays
            disableArrowLeft={disableLeftArrow}
            disableArrowRight={disableRightArrow}
            monthFormat={'MMMM'}
            testID='monthlyRestaurantCalendar'
            renderArrow={(direction) => {
                if (direction === 'left') {
                    return (
                        <ChevronLeftIcon
                            color={disableLeftArrow ? tw.color('brand-lightGray') : tw.color('brand-black')}
                        />
                    );
                } else {
                    return (
                        <ChevronRightIcon
                            color={disableRightArrow ? tw.color('brand-lightGray') : tw.color('brand-black')}
                        />
                    );
                }
            }}
            dayComponent={({ date, state }) => {
                if (state === 'disabled') {
                    return (
                        <View style={tw`h-10 w-10`}>
                            <View
                                style={[
                                    tw`rounded-full absolute top-0 bottom-0 right-0 left-0 items-center justify-center`,
                                ]}
                            >
                                <Text style={[tw`heading-4 text-gray-200`]}>
                                    {date.day}
                                </Text>
                            </View>
                        </View>
                    );
                }

                return (
                    <View style={tw`h-10 w-10`}>
                        <Pressable
                            onPress={() => handleDayPress(date)}
                            style={[
                                tw`rounded-full absolute top-0 bottom-0 right-0 left-0 items-center justify-center`,
                                date.dateString === selectedDate &&
                                    tw`bg-black`,
                            ]}
                        >
                            <Text
                                style={[
                                    tw`heading-4`,
                                    date.dateString === selectedDate
                                        ? tw`text-white`
                                        : tw`text-black`,
                                ]}
                            >
                                {date.day}
                            </Text>
                        </Pressable>
                    </View>
                );
            }}
            theme={{
                backgroundColor: tw.color('transparent'),
                calendarBackground: tw.color('transparent'),
                textDayFontSize: tw.style('heading-4').fontSize,
                textDayFontFamily: tw.style('heading-4').fontFamily,
                todayTextColor: tw.color('gray-900'),
                dayTextColor: tw.color('gray-900'),
                arrowColor: tw.color('brand-black'),
                arrowStyle: tw`p-2`,
                arrowHeight: 35,
                arrowWidth: 35,
                disabledDotColor: tw.color('brand-lightGray'),
                disabledArrowColor: tw.color('brand-lightGray'),
                textDayFontWeight: '400',
                textDayHeaderFontSize: tw.style('label-small').fontSize,
                textDayHeaderFontFamily: tw.style('label-small').fontFamily,
                textMonthFontWeight: '400',
                textMonthFontFamily: tw.style('label-large').fontFamily,
                textMonthFontSize: tw.style('label-large').fontSize,
                textDisabledColor: tw.color('gray-100'),
            }}
        />
    );
}
