import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function ChevronRightIcon({
  style = { width: 10, height: 18 },
  color = 'black',
  height = 18,
  width = 10,
}) {
  return (
    <Svg
      style={style}
      width={width}
      height={height}
      viewBox='0 0 10 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <Path
        d='M1 1L9 9L1 17'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  );
}
