import React, { useEffect, useState } from "react";
import { Dimensions } from "react-native";
import Image from "../utils/Image";

const { width } = Dimensions.get("screen");

export default function CustomImage({
	source = { url: "" } || "",
	style,
	// children = React.Component,
}) {

	const [height, setHeight] = useState(
		(typeof source === "object" && source?.url !== undefined) ||
			(typeof source !== "object" && source !== null)
			? style?.height
			: 0
	);

	return (
		<Image
			source={
				typeof source === "object" && source !== null
					? { uri: source.url, cache: Image?.cacheControl?.web }
					: source
			}
			style={[{ width: style?.width }, style, { height: style?.height }]}
			resizeMode={Image?.resizeMode?.cover}
		/>
	);
}
