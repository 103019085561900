import { graphql } from '../gql';

const AcceptDocumentVersion = graphql(`
    mutation AcceptDocumentVersion($id: ID!) {
        acceptDocumentVersion(id: $id) {
            id
        }
    }
`);

const RequiredDocuments = graphql(`
    query RequiredDocuments {
        requiredDocuments {
            document {
                title
                description
            }
            id
            version
            markdown
        }
    }
`);

export { RequiredDocuments, AcceptDocumentVersion };
