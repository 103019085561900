import React, { memo, useEffect, useState } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import tw from '../../../utils/Tailwind';
import {
    Elements,
    PaymentElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import {
    loadStripe,
} from '@stripe/stripe-js';
import Constants from 'expo-constants';
import BasicButton from '../BasicButton';
import API from '../../../utils/API';
import { PhoneInput } from '../PhoneInput';
import BasicTextInput from '../BasicTextInput';

type StripeCardFormsProps = {
    completeTransaction: any,
    validatedEmail: boolean,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    useCustomerCard: boolean,
    updateCustomerInfoFromCard: any,
};

const StripeCardForm = ({
    completeTransaction,
    validatedEmail,
    firstName,
    lastName,
    email,
    phone,
    useCustomerCard,
    updateCustomerInfoFromCard
}: StripeCardFormsProps) => {

    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState('');
    const [paymentOptions, setPaymentOptions] = useState(null);

    useEffect(() => {
        const waitForIt = async () => {
            setStripePromise(
                await loadStripe(Constants.expoConfig?.extra?.STRIPE_KEY)
            );
        };
        void waitForIt();

        API.payment.conciergeIntent().then((response) => {
            setClientSecret(response?.token);
        });
    }, []);

    const stripe_options = {
        mode: 'setup',
        currency: 'usd', // TODO: Fix currency
        paymentMethodCreation: 'manual',
        appearance: {
            theme: 'stripe',
            variables: {
                fontFamily: 'Sailec',
                borderRadius: '5px',
                colorBackground: '#FFFFFF',
                spacingGridColumn: '30px',
                spacingGridRow: '30px',
            },
            rules: {
                '.Label': {
                    fontSize: '14px',
                    marginBottom: '15px',
                    fontFamily: 'Sailec',
                },

                '.Input': {
                    boxShadow: 'none',
                    padding: '15px',
                    fontSize: '15px',
                    fontFamily: 'Sailec',
                    border: '1px solid black',
                },
            },
        },
        fonts: [
            {
                family: 'Sailec',
                src: 'url(https://book.dorsia.com/fonts/stripe-font.otf)',
            },
        ],
    };

    useEffect(() => {
        if (!clientSecret) return;
        setPaymentOptions({
            clientSecret: clientSecret,
            defaultValues: {
                billingDetails: {
                    // name: `${firstName} ${lastName}`,
                    // phone: phone,
                    // email: email,
                },
            },
            fields: {
                billingDetails: {
                    name: 'auto',
                },
            },
            wallets: {
                applePay: 'never', // doesn't work in iframe
            },
        });
    }, [clientSecret]);

    const RenderStripeForm = () => {

        const [firstNameStripe, setFirstNameStripe] = useState(firstName);
        const [lastNameStripe, setLastNameStripe] = useState(lastName);
        const [emailStripe, setEmailStripe] = useState(email);
        const [validatedEmailStripe, setValidatedEmailStripe] = useState(validatedEmail);
        const [phoneStripe, setPhoneStripe] = useState(phone);
        const [loading, setLoading] = useState(false);

        const isExternalBookingFilled =
        firstNameStripe.length > 0 &&
        lastNameStripe.length > 0 &&
        phoneStripe.length > 0 && (emailStripe.length && validatedEmailStripe);

        const [isCardComplete, setIsCardComplete] = useState(false);
        const elements = useElements();

        // useEffect(() => {
        //     console.log('useCustomerCard', useCustomerCard);
        //     updateCustomerInfo(firstNameStripe, lastNameStripe, emailStripe, phoneStripe);
        // }, [useCustomerCard]);

        // useEffect(() => {
        //     console.log('useCustomerCard', useCustomerCard);
        //     // updateCustomerInfoFromCard(firstNameStripe, lastNameStripe, emailStripe, phoneStripe);
        // }, [firstNameStripe, lastNameStripe, emailStripe, phoneStripe]);

        const getCardID = async () => {

            setLoading(true);
    
            const response = await elements.submit(); // The elements form needs to be submitted before we can get the paymentMethod
    
            const {paymentMethod, error} = await stripePromise.createPaymentMethod({
                elements,
                params: {
                  billing_details: {

                  },
                },
              });
    
              if (!error) {
                completeTransaction(paymentMethod, firstNameStripe, lastNameStripe, emailStripe, phoneStripe);
                setTimeout(() => {
                    setLoading(false);
                }, 50000);
                return
              }

            setLoading(false);

        };

        const handleEmailValidation = (email: string) => {
            if (!email) {
                setValidatedEmailStripe(false);
            }
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setValidatedEmailStripe(emailRegex.test(email));
        };

        return (
            <View style={tw`min-h-[600px] mt-2`}>

                <>
                    <View style={tw`pb-4`}>
                        <BasicTextInput
                            isReverseColor={true}
                            style={tw`py-2`}
                            textStyle={tw`text-white`}
                            label={'Guest first name'}
                            onChangeText={setFirstNameStripe}
                            placeholder={'First name'}
                            value={firstNameStripe}
                            testID='first-name-input'
                        />
                    </View>
                    <View style={tw`pb-4`}>
                        <BasicTextInput
                            isReverseColor={true}
                            style={tw`py-2`}
                            textStyle={tw`text-white`}
                            label={'Guest last name'}
                            onChangeText={setLastNameStripe}
                            placeholder={'Last name'}
                            value={lastNameStripe}
                            testID='last-name-input'
                        />
                    </View>
                    <View style={tw`pb-4`}>
                        <BasicTextInput
                            isReverseColor={true}
                            style={tw`py-2 ${!validatedEmailStripe && email ? 'border-brand-red' : ''}`}
                            textStyle={tw`text-white`}
                            label={'Email'}
                            onChangeText={(email) => {
                                setValidatedEmailStripe(true)
                                setEmailStripe(email)
                            }}
                            placeholder={'Email'}
                            value={emailStripe}
                            testID='email-input'
                            onBlur={(e) => {
                                handleEmailValidation(e.nativeEvent.text);
                            }}
                        />
                    </View>
                    <View style={tw`pb-8`}>
                        <PhoneInput
                            style={tw`ml-2 p-4 w-full`}
                            dropDownStyle={tw`label-small text-black`}
                            textStyle={tw`text-black`}
                            label={'Phone'}
                            boxStyle={tw`rounded border border-brand-black py-0`}
                            labelStyle={tw`text-black mb-2 `}
                            onParse={(phone) => {
                                setPhoneStripe(phone)
                            }}
                            placeholder={'Phone'}
                            resetPhone={() => setPhoneStripe('')}
                            dark={false}
                            testID='phone-input'
                            initialPhone={phone}
                        />
                    </View>
                </>
                <PaymentElement
                    options={paymentOptions}
                    onChange={(elementForm) => {
                        setIsCardComplete(elementForm?.complete);
                    }}
                />
                <View style={tw`pt-4`}>
                    <BasicButton
                        onPress={getCardID}
                        justText={'Submit booking'}
                        textStyle={tw`label-medium bold text-brand-white`}
                        disabledTextStyle={tw`label-medium bold text-brand-white opacity-50`}
                        disabled={!isCardComplete || !isExternalBookingFilled}
                        disabledStyle={tw`bg-brand-altPurple rounded-xl opacity-25`}
                        style={tw`bg-brand-altPurple rounded-xl`}
                        testID='submit-booking-button'
                        loadingColor='white'
                        loading={loading}
                    />
                </View>
            </View>
        );
    };

    return (
        <View style={tw`w-ful my-4`}>
            <Elements stripe={stripePromise} options={stripe_options}>
                <RenderStripeForm />
            </Elements>
        </View>
    );
};

export default memo(StripeCardForm);
