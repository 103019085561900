import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function ChevronUpSmallIcon({ color = 'white', width = 25, height = 24 }) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <Path
        d='M17.6953 14.5L12.6953 9.5L7.69531 14.5'
        stroke={color}
        strokeWidth='1.5'
      />
    </Svg>
  );
}
