import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function ChevronDownSmallIcon({ color = 'white' }) {
  return (
    <Svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <Path
        d='M7.30469 9.5L12.3047 14.5L17.3047 9.5'
        stroke={color}
        strokeWidth='1.5'
      />
    </Svg>
  );
}
