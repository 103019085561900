export type SearchableCountryCode = {
    alpha2: string;
    name: string;
    code: string;
};

export default class CountryCodeSearch {
    countryCodes: SearchableCountryCode[] = [];
    constructor(countryCodes: SearchableCountryCode[] = []) {
        this.countryCodes = countryCodes;
    }

    search(terms: string): SearchableCountryCode[] {
        // "Sanitize" search terms
        const searchTerms = terms.replace(/^\+/, '').toLowerCase();
        let alternativeSearchTerms = null;

        // Special exception for the UK
        if (searchTerms === 'uk') {
            alternativeSearchTerms = 'gb';
        }

        // If the search is empty, return all results
        if (!searchTerms) {
            return this.countryCodes;
        }

        // If the search terms are numeric, return results against the dial code
        if (searchTerms.match(/\d/) && !searchTerms.match(/[a-z]/)) {
            return this.countryCodes.filter(
                (countryCode: SearchableCountryCode) => {
                    return countryCode.code.toLowerCase() === '+' + searchTerms;
                }
            );
        }

        // Filter by country code name
        return this.countryCodes.filter(
            (countryCode: SearchableCountryCode) => {
                return (
                    countryCode.name.toLowerCase().includes(searchTerms) ||
                    countryCode.alpha2.toLowerCase() === searchTerms ||
                    countryCode.name
                        .toLowerCase()
                        .includes(alternativeSearchTerms) ||
                    countryCode.alpha2.toLowerCase() === alternativeSearchTerms
                );
            }
        );
    }
}
