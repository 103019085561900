import React, { useEffect, useState, memo } from "react";
import { View, Text, Pressable } from "react-native";
import tw from "../../utils/Tailwind";
import RestaurantInventorySlider from "./RestaurantInventorySlider";
import ChevronLeftIcon from "../icons/ChevronLeftIcon";
import ChevronRightIcon from "../icons/ChevronRightIcon";

type RestaurantInventorySliderControlProps = {
	options: Array<Object>;
	restaurant: Object;
	timezone: String;
	onPress: Function;
	item: Object;
	type: String;
	style: Object;
	useControls: Boolean;
	initialIndex: Number;
	selectedItem: Object;
	disabled: Boolean;
	lengthControlTrigger: number;
};

export default memo(function RestaurantInventorySliderControl({
	options,
	restaurant,
	timezone,
	onPress = () => {},
	item,
	type = "inventory",
	style = {},
	useControls = true,
	initialIndex = 0,
	selectedItem,
	disabled = false,
	lengthControlTrigger = 4,
}: RestaurantInventorySliderControlProps) {
	const [scrollIndex, setScrollIndex] = useState(0);
	const [reachedEnd, setReachedEnd] = useState(false);
	const [reachedStart, setReachedStart] = useState(true);
	const [scrollerOffset, setScrollerOffset] = useState(475);

	useEffect(() => {
		// setScrollIndex(initialIndex);
		setReachedEnd(false);
		setReachedStart(true);
	}, [options]);
	return (
		<View
			style={[
				tw`w-full h-full max-w-full flex-row items-center justify-start`,
				style,
			]}
			onLayout={(event) => {
				setScrollerOffset(event.nativeEvent.layout.width);
			}}
		>
			{useControls &&
				options.length >= lengthControlTrigger &&
				!reachedStart && (
					<View
						style={tw`hidden tablet:flex flex-row items-center justify-center w-0 min-h-full flex-1`}
					>
						<Pressable
							onPress={() => {
								setReachedStart(false);
								setScrollIndex(scrollIndex - 1);
							}}
							style={tw`w-10 h-10 bg-brand-white rounded-full flex-row items-center justify-center shadow-xl -mr-4 z-10`}
						>
							<ChevronLeftIcon color={"black"} width={14} />
						</Pressable>
					</View>
				)}
			<RestaurantInventorySlider
				pagingEnabled={true}
				restaurant={restaurant}
				options={options}
				onPress={onPress}
				selectedItem={selectedItem}
				timezone={timezone}
				showPrices
				initialIndex={initialIndex}
				disabled={disabled}
				scrollIndex={scrollIndex}
				// analyticsProps={analyticsProps}
				// analyticsComponentName='Restaurant: Time'
				initialNumToRender={100}
				maxToRenderPerBatch={100}
				scrollerOffset={scrollerOffset}
				type={type}
				disableIntervalMomentum={useControls ? true : false}
				handleReachedEnd={() => {
					// console.log("handleReachedEnd");
					setReachedEnd(true);
					setReachedStart(false);
				}}
				handleReachedStart={() => {
					// console.log("handleReachedStart");
					setReachedStart(true);
					setReachedEnd(false);
				}}
			/>
			{useControls && options.length >= lengthControlTrigger && !reachedEnd && (
				<View
					style={tw`flex-row items-center justify-center w-0  hidden tablet:flex`}
				>
					<Pressable
						onPress={() => {
							setReachedStart(false);
							setScrollIndex(scrollIndex + 1);
						}}
						style={tw`w-10 h-10 bg-brand-white rounded-full flex-row items-center justify-center shadow-xl -ml-4 z-20`}
					>
						<ChevronRightIcon color={"black"} width={14} />
					</Pressable>
				</View>
			)}
		</View>
	);
})
