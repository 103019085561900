const { plugin } = require('twrnc');
const Theme = require('./components/tailwind/Theme').default
const Utilities = require('./components/tailwind/Utilities').default

module.exports = {
  darkMode: false, // or 'media' or 'class'
  theme: Theme,
  variants: {
    extend: {},
  },
  plugins: [
    plugin(({ addUtilities }) => {
      addUtilities(Utilities);
    }),
  ],
};
