import { graphql } from '../gql';

const Configurations = graphql(`
    query Configurations {
        countryCodes {
            alpha2
            name
            code
        }
        dateTimeFormats {
            moment {
                date_long
                date_short
                date_dow_short
                date_dow_long
                date_slashes
                date_slashes_short
                time
            }
            dateFns {
                date_long
                date_short
                date_dow_short
                date_dow_long
                date_slashes
                date_slashes_short
                time
            }
        }
    }
`);

export { Configurations };
