import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function FrictionlessIcon({
  height = 22,
  width = 25,
  color = 'black',
}) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox='0 0 25 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <Path
        d='M3.85157 20.6C3.78046 20.6 3.70791 20.5889 3.63767 20.5652C3.359 20.4712 3.1923 20.2097 3.24067 19.9416L6.2653 12.2587L1.61898 12.8587C1.39559 12.8587 1.18974 12.7489 1.08006 12.5711C0.970368 12.3934 0.973537 12.1756 1.08869 12.0002L9.61717 1.67443C9.76975 1.44138 10.0824 1.34105 10.3614 1.43506C10.6404 1.52907 10.8068 1.79029 10.759 2.05837L7.73433 9.74156L12.3806 9.14156C12.604 9.14156 12.8099 9.25137 12.9196 9.42912C13.0298 9.60687 13.0264 9.82465 12.9109 10L4.38217 20.3252C4.26758 20.4997 4.06374 20.6 3.85157 20.6Z'
        strokeLinejoin='round'
        stroke={color}
      />
      <Path d='M15 6L21 6' stroke={color} strokeLinecap='round' />
      <Path d='M13 16L20 16' stroke={color} strokeLinecap='round' />
      <Path d='M16 11L24 11' stroke={color} strokeLinecap='round' />
    </Svg>
  );
}
