import React, { useEffect, useState, memo } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { AvailabilityOption } from '../../../classes/Types/Availability';
import tw from '../../../utils/Tailwind';
import BasicButton from '../BasicButton';
import { Reservation, Restaurant } from '../../../gql/graphql';
import { AxiosError } from 'axios';
import * as Clipboard from 'expo-clipboard';
import API from '../../../utils/API';
import PartyIcon from '../../icons/PartyIcon';
import CopyIcon from '../../icons/CopyIcon';
import SelectDropdown from 'react-native-select-dropdown';
import StripeCardForm from './StripeCardForm';
import Checkbox from '../Checkbox';
import ChevronUpSmallIcon from '../../icons/ChevronUpSmallIcon';
import ChevronDownSmallIcon from '../../icons/ChevronDownSmallIcon';

type BookOnBehalfProps = {
    availabilityOption: AvailabilityOption | null;
    restaurant: Restaurant;
    onBooking: Function;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    validatedEmail: boolean;
    bookingType: string;
    onUseCustomerCardChange: Function;
    clearBooking: boolean;
};

const BookOnBehalfForm = ({
    availabilityOption,
    restaurant,
    onBooking,
    firstName,
    lastName,
    phone,
    email,
    validatedEmail,
    bookingType,
    onUseCustomerCardChange,
    clearBooking
}: BookOnBehalfProps) => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [booking, setBooking] = useState<Reservation | undefined>();
    const [linkCopied, setLinkCopied] = useState(false);

    const [loading, setLoading] = useState(false);
    const [useCustomerCard, setUseCustomerCard] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
        object | undefined
    >();

    useEffect(() => {
        getPaymentMethods();
    }, []);

    useEffect(() => {
        setBooking(undefined);
    }, [availabilityOption, restaurant, clearBooking]);

    const getPaymentMethods = async () => {
        API.payment.paymentMethods().then((response) => {
            if (response.data.length) {
                setPaymentMethods(response.data);
                return;
            }
            if (bookingType === 'full') {
                setUseCustomerCard(true);
            }
        })
        .catch((error: AxiosError) => {
            if (bookingType === 'full') {
                setUseCustomerCard(true);
            }
        });
    };

    useEffect(() => {
        onUseCustomerCardChange(useCustomerCard);
    }, [useCustomerCard]);


    useEffect(() => {
        if (!availabilityOption) {
            setBooking(undefined);
        }
    }, [availabilityOption]);

    useEffect(() => {
        setErrorMessage(undefined);
    }, [firstName, lastName, phone, availabilityOption]);

    const submitForm = async (e) => {
        e.preventDefault();

        if (!availabilityOption) {
            return;
        }

        setLoading(true);

        API.booking
            .book({
                restaurant: restaurant.id,
                seats: availabilityOption?.seats,
                confirmed_price: availabilityOption?.price,
                seating: availabilityOption?.seating_id,
                date: availabilityOption.datetime_utc,
                phone,
                first_name: firstName,
                last_name: lastName,
            })
            .then((response) => {
                setErrorMessage(undefined);
                onBooking(true);
                setBooking(response.data);
            })
            .catch((error: AxiosError) => {
                if (error?.response?.data?.message) {
                    setErrorMessage(error.response.data.message);
                    return;
                }
                setErrorMessage('An unknown error occurred.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const submitFullForm = async (paymentMethod, firstName, lastName, email, phone) => {
        if (!availabilityOption) {
            return;
        }

        if (!useCustomerCard) {
            setLoading(true);
        }

        API.booking
            .fullBook({
                confirmed_price: availabilityOption?.price,
                date:  availabilityOption?.datetime_utc,
                first_name: firstName,
                last_name: lastName,
                name: `${firstName} ${lastName}`,
                email: email,
                phone: phone,
                cardholder_name: `${firstName} ${lastName}`,
                payment_id: paymentMethod?.id ? paymentMethod?.id : null,
                payment_method_id: selectedPaymentMethod && !paymentMethod?.id ? selectedPaymentMethod : null,
                zip_code: paymentMethod?.billing_details?.address?.postal_code ? paymentMethod?.billing_details?.address?.postal_code : null,
                restaurant_id: restaurant?.id,
                seating_id: availabilityOption?.seating_id,
                party: availabilityOption?.seats,
                promo: null,
                one_time_use: false,
            })
            .then((response) => {
                // console.log('response', response);
                setErrorMessage(undefined);
                onBooking(true);
                setBooking({fullbooking: true});
            })
            .catch((error: AxiosError) => {
                if (error?.response?.data?.message) {
                    setErrorMessage(error.response.data.message);
                    return;
                }
                // console.log('error', error);
                setErrorMessage('An unknown error occurred.');
            })
            .finally(() => {
                setLoading(false);
                getPaymentMethods();
            });
    };

    useEffect(() => {
        setErrorMessage(undefined);
        setLoading(false);
        setUseCustomerCard(false);
    }, [bookingType]);

    const isLinkFilled =
        firstName?.length > 0 &&
        lastName?.length > 0 &&
        phone?.length > 0 &&
        !!availabilityOption;

    const isBookingFilled =
        firstName?.length > 0 &&
        lastName?.length > 0 &&
        phone?.length > 0 &&
        email?.length > 0 && validatedEmail &&
        !!availabilityOption && !!selectedPaymentMethod;
    // (bookingType === 'full' && !!selectedPaymentMethod ? true : false);

    const dropDownStyles = StyleSheet.create({
        dropdownButtonStyle: {
            width: '100%',
            padding: 12,
            flex: 1,
            backgroundColor: '#E9ECEF',
            borderRadius: 12,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            paddingHorizontal: 12,
        },
        dropdownButtonTxtStyle: {
            flex: 1,
            fontSize: 18,
            fontWeight: '500',
            color: '#151E26',
        },
        dropdownButtonArrowStyle: {
            fontSize: 28,
        },
        dropdownButtonIconStyle: {
            fontSize: 28,
            marginRight: 8,
        },
        dropdownMenuStyle: {
            backgroundColor: '#E9ECEF',
            borderRadius: 8,
        },
        dropdownItemStyle: {
            width: '100%',
            flexDirection: 'row',
            paddingHorizontal: 12,
            justifyContent: 'center',
            alignItems: 'center',
            paddingVertical: 8,
            border: '1px bottom solid #151E26',
        },
        dropdownItemTxtStyle: {
            flex: 1,
            fontSize: 18,
            fontWeight: '500',
            color: '#151E26',
        },
        dropdownItemIconStyle: {
            fontSize: 28,
            marginRight: 8,
        },
    });

    return (
        <View>
            {!booking && (
                <View id='booking-form'>
                    {bookingType == 'full' && (
                        <View style={tw`flex-row items-center my-4`}>
                            <View
                                style={tw`flex-1 items-center justify-end max-w-full ${
                                    useCustomerCard ? 'opacity-10' : ''
                                }`}
                            >
                                {!!paymentMethods.length && (
                                    <SelectDropdown
                                        data={paymentMethods}
                                        onSelect={(selectedItem, index) => {
                                            // console.log(selectedItem, index);
                                            setSelectedPaymentMethod(
                                                selectedItem.id
                                            );
                                        }}
                                        disabled={useCustomerCard}
                                        renderButton={(selectedItem, isOpened) => {
                                            return (
                                                <View
                                                    style={
                                                        dropDownStyles.dropdownButtonStyle
                                                    }
                                                >
                                                    <Text
                                                        style={
                                                            dropDownStyles.dropdownButtonTxtStyle
                                                        }
                                                    >
                                                        {(selectedItem &&
                                                            selectedItem.name && (
                                                                <Text>
                                                                    {
                                                                        selectedItem.name
                                                                    }
                                                                </Text>
                                                            )) ||
                                                            'Choose saved card'}
                                                    </Text>
                                                    {!isOpened && (
                                                        <ChevronDownSmallIcon
                                                            color={tw.color(
                                                                'black'
                                                            )}
                                                        />
                                                    )}
                                                    {isOpened && (
                                                        <ChevronUpSmallIcon
                                                            color={tw.color(
                                                                'black'
                                                            )}
                                                        />
                                                    )}
                                                </View>
                                            );
                                        }}
                                        renderItem={(item, index, isSelected) => {
                                            return (
                                                <View
                                                    style={{
                                                        ...dropDownStyles.dropdownItemStyle,
                                                        ...(isSelected && {
                                                            backgroundColor:
                                                                '#D2D9DF',
                                                        }),
                                                    }}
                                                >
                                                    <Text
                                                        style={
                                                            dropDownStyles.dropdownItemTxtStyle
                                                        }
                                                    >
                                                        {item.name}
                                                        {/* {'\n'} */}
                                                        {/* {item.cardLastFour} |{' '}
                                                        {item.cardType} */}
                                                    </Text>
                                                </View>
                                            );
                                        }}
                                        showsVerticalScrollIndicator={false}
                                        dropdownStyle={
                                            dropDownStyles.dropdownMenuStyle
                                        }
                                        testID='saved-card-dropdown'
                                    />
                                )}
                            </View>
                            <View style={tw`flex pl-3 items-center`}>
                                <Checkbox
                                    value={useCustomerCard}
                                    checked={useCustomerCard}
                                    onChange={() =>
                                        setUseCustomerCard(!useCustomerCard)
                                    }
                                >
                                    <Text
                                        style={tw`label-small text-brand-dark px-2`}
                                    >
                                        Add new card
                                    </Text>
                                </Checkbox>
                            </View>
                        </View>
                    )}

                    {errorMessage && (
                        <Text style={tw`body text-brand-red text-center py-4`}>
                            {errorMessage}
                        </Text>
                    )}

                    {bookingType === 'full' && useCustomerCard && (
                        <StripeCardForm
                            completeTransaction={(paymentMethod, firstName, lastName, email, phone) => {
                                submitFullForm(paymentMethod, firstName, lastName, email, phone)
                            }}
                            // updateCustomerInfoFromCard={(firstName, lastName, email, phone) => {
                            //     handleUseCustomerInfoChange(firstName, lastName, email, phone)
                            // }}
                            useCustomerCard={useCustomerCard}
                            firstName={firstName}
                            lastName={lastName}
                            email={email}
                            phone={phone}
                            validatedEmail={validatedEmail}
                        />
                    )}

                    {!useCustomerCard && bookingType == 'full' && (
                        <View style={tw`pt-4`}>
                            <BasicButton
                                onPress={() => submitFullForm(null, firstName, lastName, email, phone)}
                                justText={'Submit booking'}
                                textStyle={tw`label-medium bold text-brand-white`}
                                disabledTextStyle={tw`label-medium bold text-brand-white opacity-50`}
                                disabled={!selectedPaymentMethod || !isBookingFilled}
                                disabledStyle={tw`bg-brand-altPurple rounded-xl opacity-25`}
                                style={tw`bg-brand-altPurple rounded-xl`}
                                testID='submit-booking-button'
                                loadingColor='white'
                                loading={loading}
                            />
                        </View>
                    )}

                    {bookingType == 'link' && (
                        <View style={tw`pt-4`}>
                            <BasicButton
                                onPress={submitForm}
                                justText={'Text booking link'}
                                textStyle={tw`label-medium bold text-brand-white`}
                                disabledTextStyle={tw`label-medium bold text-brand-white opacity-50`}
                                disabled={!isLinkFilled}
                                disabledStyle={tw`bg-brand-altPurple rounded-xl opacity-25`}
                                style={tw`bg-brand-altPurple rounded-xl`}
                                testID='submit-booking-button'
                                loadingColor='white'
                                loading={loading}
                            />
                        </View>
                    )}
                </View>
            )}

            {!!booking?.booking_link && (
                <View>
                    <View style={tw`pt-10 pb-2`}>
                        <PartyIcon
                            color={tw.color('text-brand-altPurple')}
                            width={50}
                            height={50}
                        />
                    </View>
                    <Text style={tw`heading-3 bold pb-4 `}>
                        Booking link sent!
                    </Text>
                    <Text style={tw`heading-4 pb-4`}>
                        The phone number should receive a text message to accept
                        their booking. You can check the status under All
                        booking links.
                    </Text>
                    <BasicButton
                        onPress={() => {
                            Clipboard.setStringAsync(booking.booking_link);
                            setLinkCopied(true);
                        }}
                        textStyle={tw`label-medium bold text-brand-altPurple`}
                        disabledTextStyle={tw`label-medium bold text-brand-white opacity-50`}
                        disabled={!booking.booking_link}
                        disabledStyle={tw`border-brand-altPurple rounded-xl opacity-25`}
                        style={tw`bg-brand-white border-2 border-brand-altPurple rounded-xl mt-4 flex-row items-center justify-center`}
                    >
                        <Text
                            style={tw`label-medium bold text-brand-altPurple mr-3`}
                        >
                            {linkCopied ? 'Link copied!' : 'Copy link'}
                        </Text>
                        <CopyIcon
                            width={25}
                            height={25}
                            color={tw.color('text-brand-altPurple')}
                        />
                    </BasicButton>
                </View>
            )}

            {booking?.fullbooking && (
                <View>
                    <View style={tw`pt-10 pb-2`}>
                        <PartyIcon
                            color={tw.color('text-brand-altPurple')}
                            width={50}
                            height={50}
                        />
                    </View>
                    <Text style={tw`heading-3 bold pb-4 `}>
                        Booking complete!
                    </Text>
                    <Text style={tw`heading-4 pb-4`}>
                        The guest will receive an email with their booking confirmation.
                    </Text>
                </View>
            )}
        </View>
    );
};

export default memo(BookOnBehalfForm);
