import React, { useEffect, useRef, memo } from 'react';
import { View, FlatList } from 'react-native';
import RestaurantInventoryButtons from './RestaurantInventoryButtons';
import RestaurantNubsButtons from './RestaurantNubsButtons';
import tw from '../../utils/Tailwind';
import API from '../../utils/API';

// import useHorizontalScrollAnalytics from './useHorizontalScrollAnalytics';

const renderHeader = () => {
    return <View style={tw``} />;
};

const renderFooter = () => {
    return <View style={tw``} />;
};

type RestaurantInventorySliderProps = {
    options: Array<Object>;
    restaurant: Object;
    timezone: String;
    onPress: Function;
    item: Object;
    type: String;
    style: Object;
    useControls: Boolean;
    initialIndex: Number;
    selectedItem: Object;
    disabled: Boolean;
    shouldResetPosition: Boolean;
    showPrices: Boolean;
    pagingEnabled: Boolean;
    disableIntervalMomentum: Boolean;
    showsHorizontalScrollIndicator: Boolean;
    scrollIndex: Number;
    handleReachedEnd: Function;
    handleReachedStart: Function;
    scrollerWidth: Number;
    scrollerOffset: Number;
    footerElement: Function;
    initialNumToRender: Number | null;
    maxToRenderPerBatch: Number | null;
};

export default memo(function RestaurantInventorySlider({
    options = [],
    selectedItem,
    timezone,
    shouldResetPosition = false,
    showPrices = false,
    onPress = () => {},
    footerElement = renderFooter,
    initialIndex = 0,
    scrollIndex = 0,
    disabled = false,
    // analyticsProps = {},
    // analyticsComponentName = '',
    initialNumToRender=5,
    maxToRenderPerBatch=5,
    scrollerWidth = 300,
    scrollerOffset = 475,
    handleReachedEnd = () => {},
    handleReachedStart = () => {},
    type = 'inventory',
    restaurant = {},
    pagingEnabled = false,
    disableIntervalMomentum = false,
    showsHorizontalScrollIndicator = false,
}: RestaurantInventorySliderProps) {

    const flatlistRef = useRef<FlatList>(null);
    const hasUserScrolled = useRef(false);

    useEffect(() => {
        // console.log('scrollIndex', scrollIndex, flatlistRef);

        if (scrollIndex > options.length - 1) {
            flatlistRef?.current?.scrollToIndex({
                index: options.length - 1,
                viewPosition: 0,
                animated: true,
            });
            return;
        }

        if (scrollIndex < 0) {
            flatlistRef?.current?.scrollToIndex({
                index: 0,
                viewPosition: 0,
                animated: true,
            });
            return;
        }

        flatlistRef?.current?.scrollToIndex({
            index: scrollIndex,
            viewPosition: 0,
            animated: true,
        });
    }, [scrollIndex]);

    useEffect(() => {
        if (!flatlistRef.current || !shouldResetPosition) return;
        flatlistRef.current?.scrollToOffset({ x: 0, y: 0, animated: false });
    }, [shouldResetPosition]);

    // const { handleScrollBegin, handleScrollEnd } = useHorizontalScrollAnalytics(
    //   analyticsProps,
    //   analyticsComponentName
    // );

    const onContentSizeChange = () => {
        if (
            flatlistRef.current &&
            flatlistRef.current.scrollToIndex &&
            options &&
            options.length &&
            initialIndex > 1 &&
            initialIndex < options.length &&
            options.length > initialIndex
            &&
            !hasUserScrolled.current
        ) {
            setTimeout(() => {
                flatlistRef?.current?.scrollToIndex({
                    index: initialIndex,
                    viewPosition: 0.5,
                    animated: false,
                });
            }, 100);
        }
    };

    useEffect(() => {
        if (options?.length > 0 && flatlistRef?.current && !initialIndex) {
            flatlistRef?.current?.scrollToIndex({
                index: 0,
                viewPosition: 0.5,
                animated: true,
            });
            return;
        }

        if (
            options?.length > 0 &&
            options?.length > initialIndex &&
            initialIndex >= 0 &&
            flatlistRef?.current
        ) {
            flatlistRef?.current?.scrollToIndex({
                index: initialIndex,
                viewPosition: 0.5,
                animated: true,
            });
        }
    }, [options, initialIndex]);

    const onScrollToIndexFailed = (info) => {
        console.log('onScrollToIndexFailed', info);
        flatlistRef?.current?.scrollToOffset({
            offset: info.averageItemLength * info.index,
            animated: true,
        });

        setTimeout(() => {
            if (options.length > 0 && flatlistRef.current !== null) {
                flatlistRef?.current?.scrollToIndex({
                    index: info.index,
                    animated: true,
                    viewPosition: 0.5,
                });
            }
        }, 100);
    };

    const onScrollBeginDrag = (e) => {
      hasUserScrolled.current = true;
      // handleScrollBegin(e);
    };

    const renderItem = ({ item, index }) => {

        if (type === 'inventory')
            return (
                <RestaurantInventoryButtons
                    item={item}
                    onPress={() => {
                        onPress(item, index);
                    }}
                    timezone={timezone}
                    showPrices={showPrices}
                    selectedItem={selectedItem}
                    disabled={disabled}
                    type={type}
                    key={index}
                />
            );

        if (type === 'tables')
            return (
                <RestaurantNubsButtons
                    item={item}
                    onPress={() => {
                        onPress(item, index);
                    }}
                    timezone={timezone}
                    selectedItem={selectedItem}
                    disabled={disabled}
                    key={index}
                />
            );

        return <></>;
    };

    return (
        <FlatList
            id={'RestaurantInventorySlider-' + (restaurant?.id ?? '') + '-' + type}
            style={tw`flex-grow`}
            contentContainerStyle={tw`pr-3`}
            decelerationRate='fast'
            initialNumToRender={initialNumToRender as number}
            maxToRenderPerBatch={maxToRenderPerBatch as number}
            ref={flatlistRef}
            data={options}
            keyExtractor={(_item, index) => {
                return index.toString();
            }}
            renderItem={renderItem}
            ListHeaderComponent={renderHeader}
            ListFooterComponent={footerElement as React.ComponentType<any>}
            showsHorizontalScrollIndicator={showsHorizontalScrollIndicator as boolean}
            horizontal
            onContentSizeChange={onContentSizeChange}
            pagingEnabled={pagingEnabled as boolean}
            onScrollToIndexFailed={onScrollToIndexFailed}
            disableIntervalMomentum={disableIntervalMomentum as boolean}
            onScrollBeginDrag={onScrollBeginDrag}
            // onScrollEndDrag={handleScrollEnd}
            // onMomentumScrollEnd={(e) => {
            //     console.log('handleScrollEnd', e.nativeEvent);
            // }}
            onEndReached={(info: { distanceFromEnd: number }) => handleReachedEnd(info.distanceFromEnd)}
            onStartReached={(info: { distanceFromStart: number }) => handleReachedStart(info.distanceFromStart)}
            getItemLayout={(_data, index) => {
                //NOTE: This is only used for scrolling the slider on desktop
                // console.log('getItemLayout', index, data?.length);
                return { length: options.length, offset: scrollerOffset * index, index: index as number };
            }}
        />
    );
});
