import React, { memo, useEffect, useRef, useState } from 'react';
import { Image, StyleSheet, Text, Pressable, View } from 'react-native';
import MapMarkerIcon from '../icons/MapMarkerIcon';
import tw from '../../utils/Tailwind';
import CustomImage from './CustomImage';
import VerticalGradient from '../utils/VerticalGradient';
import { useSearchContext } from '../../contexts/SearchContext';
import { Cuisine } from '../../gql/graphql';
import RestaurantInventorySlider from './RestaurantInventorySlider';
import Shimmer from '../loaders/Shimmer';
import API from '../../utils/API';
import { useAuthContext } from '../../contexts/AuthContext';
import FrictionlessIcon from '../icons/FrictionlessIcon';
import RestaurantInventorySliderControl from './RestaurantInventorySliderControl';

// export const isClosedBeforeLaunch = (restaurant, date) => {
//   return restaurant.launching_at && isBefore(date, restaurant.launching_at);
// };
// export const isClosedToReservations = (restaurant, date) => {
//   return (
//     restaurant.reservations_open_at &&
//     isBefore(date, restaurant.reservations_open_at)
//   );
// };
// export const handleDateParam = (restaurant, selectedDate, storeDate) => {
//   if (selectedDate) return selectedDate;
//   else if (isClosedBeforeLaunch(restaurant, storeDate))
//     return moment(restaurant.launching_at).format('YYYY-MM-DD');
//   else if (isClosedToReservations(restaurant, storeDate))
//     return moment(restaurant.reservations_open_at).format('YYYY-MM-DD');
//   else return storeDate;
// };

function RestaurantTile({
    // navigation,
    // route,
    // currentPromos = [],
    restaurant,
    onRestaurantPress,
    onAvailabilityPress,
    style,
    index,
    singleDateAvailability,
    // analyticsProps,
    // marketingSquares,
}) {
    if (!restaurant) return null;

    const { filterData, setFilterData } = useSearchContext();
    const timezone = restaurant?.timezone;
    const { currentUser } = useAuthContext();

    const styles = StyleSheet.create({
        logoImage: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            // resizeMode: 'contain',
        },
    });

    const [isVisible, setIsVisible] = useState(false);
    const [availability, setAvailability] = useState(null);
    const [noAvailability, setNoAvailability] = useState(false);

    const ref = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) =>
            setIsVisible(entry.isIntersecting)
        );

        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, [ref]);

    const getAvailability = () => {
        API.restaurant
            .availability({
                restaurant_id: restaurant.id,
                party_size: filterData.party_size,
                start_date: filterData.start_date,
                end_date: singleDateAvailability ? null : filterData.end_date,
            })
            .then((data) => {
                setAvailability(data.availability ?? []);
                if (data.availability.length === 0) {
                    setNoAvailability(true);
                    return;
                }
                setNoAvailability(false);
            });
    };

    useEffect(() => {
        setAvailability(null);
        if (isVisible) getAvailability();
    }, [restaurant]);

    useEffect(() => {
        if (isVisible && !availability?.length) getAvailability();
    }, [isVisible, filterData.start_date, filterData.party_size]);

    return (
        <View style={style} key={index} ref={ref}>
            <Pressable
                onPress={() => {
                    onRestaurantPress(restaurant);
                }}
            >
                <View style={tw`pt-0`}>
                    <View style={tw`h-52 max-h-52`}>
                        {isVisible && (
                            <CustomImage
                                source={{
                                    url: restaurant?.heroMedia[0]
                                        ?.thumbnail_url,
                                }}
                                style={tw`absolute top-0 left-0 right-0 bottom-0 h-52 max-h-52 w-full rounded-xl overflow-hidden max-w-full`}
                            />
                        )}
                        <View
                            style={tw`absolute top-0 left-0 right-0 bottom-0 rounded-t-xl overflow-hidden`}
                        >
                            <VerticalGradient height={125} />
                        </View>

                        <View
                            style={tw`absolute top-0 left-0 right-0 bottom-0 h-52 max-h-52 w-full flex-row items-center justify-center `}
                        >
                            <View
                                style={tw`flex-row items-center justify-center h-[70%] w-[70%]`}
                            >
                                <Image
                                    source={{
                                        uri: restaurant.transparentLogo
                                            ?.full_url,
                                    }}
                                    style={styles.logoImage}
                                    resizeMode='contain'
                                />
                            </View>
                        </View>
                    </View>
                    <View style={tw` py-4`}>
                        <View style={tw`flex-row items-end justify-start`}>
                            <Text
                                style={tw`heading-3 text-black -tracking-[.01]`}
                                lineBreakMode='tail'
                                numberOfLines={1}
                            >
                                {restaurant.name}
                            </Text>
                            {restaurant?.neighborhood?.name && (
                                <View
                                    style={tw`flex-row items-center justify-start flex-grow px-2 pb-1`}
                                >
                                    <MapMarkerIcon
                                        color={tw.color('text-brand-black')}
                                        strokeWidth={0}
                                        style={tw`mx-1 opacity-50`}
                                    />
                                    <Text
                                        style={tw`label-xxsmall text-brand-black opacity-50`}
                                    >
                                        {restaurant?.neighborhood?.name}
                                    </Text>
                                </View>
                            )}
                            <View
                                style={tw`flex-row items-center justify-end pl-2 pb-1`}
                            >
                                {restaurant?.has_frictionless && (
                                    <FrictionlessIcon
                                        color='black'
                                        width={22}
                                    />
                                )}
                            </View>
                        </View>

                        <View style={tw`flex-row items-start`}>
                            {restaurant?.cuisines?.map(
                                (cuisine: Cuisine, index) => {
                                    return (
                                        <Text
                                            style={tw`label-xxxsmall text-brand-black mt-1.5 mr-1 border border-brand-altGray rounded-full px-2 py-1 uppercase tracking-widest`}
                                            key={index}
                                        >
                                            {cuisine.name}
                                        </Text>
                                    );
                                }
                            )}
                        </View>

                        <Text
                            style={tw`label-xsmall text-brand-black mt-1.5 h-11`}
                            lineBreakMode='tail'
                            numberOfLines={2}
                        >
                            {restaurant?.short_description}
                        </Text>
                    </View>
                </View>
            </Pressable>

            <View style={tw`flex-row items-center justify-start h-22`}>
                {noAvailability && (
                    <Text style={tw`text-left label-small`}>
                        No availability for the selected date
                    </Text>
                )}
                {isVisible && availability?.length > 0 && (
                    <RestaurantInventorySliderControl
                        options={availability}
                        restaurant={restaurant}
                        type='inventory'
                        onPress={onAvailabilityPress}
                        timezone={timezone}
                        style={tw``}
                        lengthControlTrigger={3}
                    />
                )}
                {isVisible && !availability?.length && !noAvailability && (
                    <View
                        style={tw`flex-row items-center gap-3 opacity-40 overflow-hidden max-w-full`}
                    >
                        <View
                            style={tw`bg-gray-200 h-22 w-1/3 rounded-lg overflow-hidden`}
                        >
                            <Shimmer />
                        </View>
                        <View
                            style={tw`bg-gray-200 h-22 w-1/3 rounded-lg overflow-hidden`}
                        >
                            <Shimmer />
                        </View>
                        <View
                            style={tw`bg-gray-200 h-22 w-1/3 rounded-lg overflow-hidden`}
                        >
                            <Shimmer />
                        </View>
                    </View>
                )}
            </View>
        </View>
    );
}

export default memo(RestaurantTile);
