import React from 'react';
import Svg, { Path, G } from 'react-native-svg';

export default function SearchThinIcon({
  color = 'black',
  opacity = 1,
  strokeWidth = 1,
}) {
  return (
    <Svg
      width='20'
      height='20'
      viewBox='0 0 22 22'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <G stroke={color} opacity={opacity} strokeWidth={strokeWidth}>
        <Path
          d='M17.0026 9.78746C17.0026 13.8041 13.7464 17.0603 9.72972 17.0603C5.71302 17.0603 2.45685 13.8041 2.45685 9.78746C2.45685 5.77076 5.71302 2.51459 9.72972 2.51459C13.7464 2.51459 17.0026 5.77076 17.0026 9.78746Z'
          fill={'transparent'}
        />
        <Path d='M20.0431 19.9854L14.9155 14.8578' strokeLinecap='round' />
      </G>
    </Svg>
  );
}
