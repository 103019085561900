import { graphql } from '../gql';

const Restaurants = graphql(`
    query RestaurantsByArea(
        $area_id: ID!
        $party_size: Int!
        $start_date: String!
        $end_date: String
    ) {
        restaurantsByArea(
            area_id: $area_id
            party_size: $party_size
            start_date: $start_date
            end_date: $end_date
        ) {
            name
            id
            short_description
            address
            is_new_restaurant
            has_frictionless
            transparentLogo {
                full_url
                thumbnail_url
                type
            }
            heroMedia {
                full_url
                thumbnail_url
                type
            }
            neighborhood {
                id
                name
            }
            cuisines {
                id
                name
            }
        }
    }
`);

const RestaurantSearch = graphql(`
    query RestaurantSearch($search: String!) {
        restaurantSearch(search: $search) {
            data {
                id
                name
                area {
                    id
                    name
                }
                neighborhood {
                    id
                    name
                }
            }
        }
    }
`);

const GetRestaurant = graphql(`
    query GetRestaurant($id: ID!) {
        restaurant(id: $id) {
            id
            name
            address
            has_frictionless
            area {
                id
                name
            }
            neighborhood {
                id
                name
            }
        }
    }
`);

export { Restaurants, RestaurantSearch, GetRestaurant };
