import React from "react";
import Svg, { Path, Circle } from "react-native-svg";

export default function CuisinesIcon({
	style = {},
	color = "black",
	height = 16,
	width = 16,
}) {
	return (
		<Svg
			style={style}
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<Circle cx="8" cy="8" r="7.5" stroke={color} />
			<Path
				d="M3.08838 8.24868C3.08838 6.14305 4.48819 3.14062 7.97621 3.14062C11.4642 3.14062 12.9473 5.95379 12.9473 8.24868C12.9473 10.5436 10.6355 12.8979 7.97621 12.8979"
				stroke={color}
				strokeLinecap="round"
			/>
			<Path
				d="M3.87793 10.5605C4.18879 11.295 5.12123 12.1145 5.82762 12.3688"
				stroke={color}
				strokeLinecap="round"
			/>
		</Svg>
	);
}
