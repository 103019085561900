import React, { useEffect, useState } from 'react';
import tw from '../../utils/Tailwind';
import { TextInput, View, Text, StyleSheet, Pressable } from 'react-native';
import HttpClient from '../../utils/HttpClient';
import { RestaurantSearch } from '../../queries/Restaurants';
import { Restaurant, RestaurantSearchQuery } from '../../gql/graphql';
import SearchIcon from '../icons/SearchIcon';
import CloseIcon from '../icons/CloseIcon';

const RestaurantSearchField = ({ onRestaurantPress, style }) => {
    const [search, setSearch] = useState('');
    const [results, setResults] = useState<Restaurant[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!search) {
            setResults([]);
            return;
        }

        setLoading(true);

        HttpClient.graphqlQuery(RestaurantSearch, { search }).then(
            (data: RestaurantSearchQuery) => {
                setResults(data.restaurantSearch?.data ?? []);
                setLoading(false);
            }
        );
    }, [search]);

    const styles = StyleSheet.create({
        input: {
            outlineStyle: 'none',
            // border: '1px solid red',
        },
    });

    return (
        <View style={[tw`absolute flex-1 h-full w-full`, style]}>
            <View
                style={tw`relative top-0 left-0 right-0 w-full max-w-100 mx-auto `}
            >
                <TextInput
                    placeholder={'Search restaurants'}
                    style={[
                        tw`label-small border border-brand-lightGray py-3 px-4 rounded-xl text-brand-black z-20 bg-brand-white z-20 ${
                            search ? 'shadow-md border-brand-lightGray' : ''
                        }`,
                        styles.input,
                    ]}
                    value={search}
                    onChangeText={(text) => setSearch(text)}
                />
                {search?.length > 0 ? (
                    <Pressable
                        onPress={() => setSearch('')}
                        style={tw`absolute top-4 right-4 z-30 opacity-50`}
                    >
                        <CloseIcon color={'black'} opacity={0.4} />
                    </Pressable>
                ) : (
                    <View style={tw`absolute top-4 right-4 z-30 opacity-50`}>
                        <SearchIcon opacity={0.4} />
                    </View>
                )}

                {results.length > 0 && (
                    <View
                        style={tw`bg-brand-white -mt-4 pt-4 w-[90%] mx-auto rounded-b-xl shadow-2xl z-10`}
                    >
                        {results.map((restaurant: Restaurant, index: number) => {
                            return (
                                <Pressable
                                    testID={`search-result-${index}`}
                                    key={restaurant.id}
                                    onPress={() =>
                                        onRestaurantPress(restaurant)
                                    }
                                    style={tw`py-2 text-brand-black px-4 py-4`}
                                >
                                    <Text style={tw`text-brand-black`}>
                                        {restaurant.name} -{' '}
                                        {restaurant.area?.name}
                                    </Text>
                                </Pressable>
                            );
                        })}
                    </View>
                )}

                {!results.length && search.length > 0 && !loading && (
                    <View
                        style={tw`bg-brand-white -mt-4 pt-4 w-[90%] mx-auto rounded-b-xl shadow-2xl z-10`}
                    >
                        <Text style={tw`text-brand-black text-center py-4 `}>
                            No results found
                        </Text>
                    </View>
                )}
            </View>
        </View>
    );
};

export default RestaurantSearchField;
