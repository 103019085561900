import React from 'react';
import Svg, { Defs, LinearGradient, Rect, Stop } from 'react-native-svg';
import { Dimensions } from 'react-native';

const { width } = Dimensions.get('screen');

export default function VerticalGradient({
  height = 93,
  color = 'black',
  fillOpacity = 0.7,
  reverse = false,
  stops = [
    {
      offset: 0,
      stopOpacity: 0.7,
    },
    {
      offset: 1,
      stopOpacity: 0,
    },
  ],
}): JSX.Element {
  return (
    <Svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <Rect
        width={width}
        height={height}
        fill='url(#paint0_linear_4845_5947)'
        fillOpacity={fillOpacity}
      />
      <Defs>
        <LinearGradient
          id='paint0_linear_4845_5947'
          x1='195'
          y1='0'
          x2='195'
          y2={height}
          gradientUnits='userSpaceOnUse'
        >
          {stops.map((stop, index) => {
            return (
              <Stop
                key={`${index}-gradient-${stop.offset}`}
                stopColor={color}
                offset={stop.offset}
                stopOpacity={stop.stopOpacity}
              />
            );
          })}
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
