import React, {createContext, PropsWithChildren, useContext, useState} from 'react';
import {FilterData} from "../classes/RestaurantFilters/Filterable";
import {format} from "date-fns";

const SearchContext = createContext({});

export const useSearchContext = () => useContext(SearchContext);

const SearchProvider = (props: PropsWithChildren<any>) => {
    const today = format(new Date(), 'yyyy-MM-dd')

    const defaultFilters = {
        area: 1,
        cuisines: [],
        end_date: null,
        neighborhoods: [],
        party_size: 0,
        start_date: today
    };

    const [filterData, setFilterData] = useState<FilterData>(defaultFilters);

    return <SearchContext.Provider value={{filterData, setFilterData}}>{props.children}</SearchContext.Provider>
}

export default SearchProvider;