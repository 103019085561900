import React, { memo } from 'react';
import { Pressable, Text, View } from 'react-native';
import GuestIcon from '../icons/GuestIcon';
import tw from '../../utils/Tailwind';

const NubsItemButton = ({
  item,
  selectedItem,
  disabled,
  timezone,
  onPress = () => {},
}) => {
  const isSelected = selectedItem === item;
  const handleClick = () => onPress(item);

  return (
    <Pressable
      onPress={handleClick}
      style={[
        tw`my-2 py-2 px-3 mr-[12px] rounded-[14px]`,
        isSelected ? tw`bg-black` : tw`bg-inventory-background`,
        disabled ? tw`opacity-25` : tw``,
      ]}
      disabled={disabled}
    >
      <View style={tw`flex-1 items-start my-0.5`}>
        <View style={tw`flex-row items-center`}>
          <GuestIcon
            color={isSelected ? 'white' : 'black'}
            height={18}
            width={18}
          />
          <Text
            style={tw`button-small ml-1 ${
              isSelected ? 'text-white' : 'text-black'
            }`}
          >
            {item.seats}
          </Text>
        </View>
        <Text
          style={[
            tw`label-xsmall`,
            isSelected ? tw`text-white` : tw`text-black`,
          ]}
        >
          {item.price__currency}/person
        </Text>
        {item.type && (
          <Text
            style={[
              tw`label-xsmall`,
              isSelected ? tw`text-white` : tw`text-black`,
            ]}
          >
            {item?.type?.slice(0, 12)}
            {item?.type?.length > 12 && '...'}
          </Text>
        )}
      </View>
    </Pressable>
  );
};

const RestaurantNubsButtons = ({
  item,
  timezone = 'America/New_York',
  selectedItem = null,
  showPrices = false,
  onPress = () => {},
  disabled = false,
}) => {
  return (
    <NubsItemButton
      item={item}
      onPress={onPress}
      disabled={disabled}
      showPrices={showPrices}
      selectedItem={selectedItem}
      timezone={timezone}
    />
  );
};

export default memo(RestaurantNubsButtons);
