import React from 'react';
import tw from '../../utils/Tailwind';
import { Pressable, View, Text } from 'react-native';
import TinyCheckIcon from '../icons/TinyCheckIcon';

export default function Checkbox({
    value,
    children,
    checked = false,
    onChange,
    dark = false,
    style = {},
    boxStyle = {},
    checkSize = 11,
    useIcon = true,
    activeOpacity = 0.2,
    activeFillColor = null,
    testID = 'checkbox-test-id',
    CustomIcon = null,
    textLeft = false,
    icon_color = dark ? 'white' : 'black',
}) {
    const handlePress = () => {
        onChange(value);
    };

    const bg_color =
        dark && !activeFillColor
            ? 'bg-brand-checkboxDark'
            : !dark && !activeFillColor
              ? 'bg-brand-checkboxLight'
              : null;

    return (
        <View style={[tw`flex items-center my-0.8 `, textLeft ? tw`flex-row-reverse justify-start` : tw`flex-row`, boxStyle]}>
            <Pressable
                onPress={handlePress}
                activeOpacity={activeOpacity}
                testID={testID}
                style={[
                    { outlineStyle: 'none' }
                ]}
            >
                <View
                    style={[
                        tw`flex min-h-5 min-w-5 rounded flex-row items-center justify-center ${bg_color}`,
                        checked ? tw`${activeFillColor}` : null,
                        style,
                    ]}
                >
                    {checked && useIcon && (
                        <TinyCheckIcon
                            color={tw.color(icon_color)}
                            height={checkSize}
                            width={checkSize}
                        />
                    )}
                </View>
            </Pressable>
            <Pressable
                onPress={handlePress}
                activeOpacity={activeOpacity}
                testID={testID}
                style={[tw`flex-row items-center justify-start w-full`, textLeft ? tw`pl-2` : tw`pr-2`, { outlineStyle: 'none' }]}
            >
                {children}
            </Pressable>
        </View>
    );
}
