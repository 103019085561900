import DerivableFilterOption from "../DerivableFilterOption";
import {FilterableRestaurant} from "../FilterableRestaurant";
import {SimpleFilter} from "../Filterable";
import {sortByNameAttribute} from "../../helpers";

export default class CuisineOptions implements DerivableFilterOption {
    options(restaurants: FilterableRestaurant[]): SimpleFilter[] {
        const allOptions = {};

        restaurants.forEach((restaurant: FilterableRestaurant) => {
            restaurant.cuisines.forEach((cuisine: SimpleFilter) => {
                allOptions[cuisine.id] = cuisine;
            })
        })

        // @ts-ignore
        return Object.values(allOptions).sort(sortByNameAttribute);
    }
}