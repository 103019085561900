import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function TinyCheckIcon({ color = 'white', height = 11, width = 11 }) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <Path
        d='M13 1L1 13'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Path
        d='M1 1L13 13'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  );
}
