import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { View, Text, FlatList, Dimensions } from 'react-native';
import { Restaurant } from '../../gql/graphql';
import tw from '../../utils/Tailwind';
import RestaurantTile from '../items/RestaurantTile';

type RestaurantListProps = {
    restaurants: Restaurant[];
    onRestaurantPress: Function;
    onAvailabilityPress: Function;
    singleDateAvailability?: boolean;
};

const analyticsProps = {
    component: 'RestaurantList',
    flow: 'Main',
};

const RestaurantList = ({
    restaurants,
    onRestaurantPress,
    onAvailabilityPress,
    singleDateAvailability,
}: RestaurantListProps) => {
    const scrollRef = useRef();

    // const scrollHomeToTop = (animated) => {
    // 	scrollRef.current?.scrollToOffset({
    // 		offset: 0,
    // 		animated: animated ? animated : false,
    // 	});
    // };

    // const { handleScrollBegin, handleScrollEnd } =
    // 	useScrollAnalytics(analyticsProps);

    // return null;
    const { width } = Dimensions.get('window');

    const [columns, setColumns] = useState(3);

    useEffect(() => {
        if (width < 648) setColumns(1);
        else if (width < 1024) setColumns(2);
        else setColumns(3);
    }, [width]);

    return (
        <FlatList
            ref={scrollRef}
            data={restaurants}
            style={tw`pt-8 pb-2 bg-brand-white`}
            contentContainerStyle={tw`mx-auto width-limit-wide w-full flex-1 px-4 tablet:px-0 gap-4`}
            keyExtractor={(item, index) => item + index}
            numColumns={columns}
            vertical
            initialNumToRender={10}
            maxToRenderPerBatch={10}
            windowSize={11}
            renderItem={({ item, index }) => {
                return (
                    <View style={tw`w-1/${columns}`}>
                        <RestaurantTile
                            key={index}
                            onRestaurantPress={() => onRestaurantPress(item)}
                            onAvailabilityPress={(avail) =>
                                onAvailabilityPress(item, avail)
                            }
                            restaurant={item}
                            singleDateAvailability={singleDateAvailability}
                            style={tw`flex-1 mb-8 mx-1 lg:mx-2 tablet:mx-4 border-0`}
                        />
                    </View>
                );
            }}
            key={columns}
        />
    );
};

export default RestaurantList;
