export default class SectionListGenerator {
    static generate(objects: Object[], attribute: string) {
        const transformed = [];
        const sortedSections = [];

        objects.forEach((object) => {
            if (!object[attribute]) return;

            const group = object[attribute].toUpperCase().charAt(0);

            if (transformed[group]) {
                transformed[group].push(object);
            } else {
                transformed[group] = [object];
            }
        });

        Object.keys(transformed)
            .sort()
            .forEach((group) => {
                const sortedGroupRegions = transformed[group].sort((a, b) =>
                    a[attribute].toUpperCase() < b[attribute].toUpperCase()
                        ? -1
                        : 1
                );
                sortedSections.push({
                    title: group,
                    data: sortedGroupRegions,
                });
            });

        return sortedSections;
    }
}
