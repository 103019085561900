import React, { memo } from 'react';
import { Pressable, Text, View } from 'react-native';
import ChevronRightIcon from '../icons/ChevronRightIcon';
import GuestIcon from '../icons/GuestIcon';
import tw from '../../utils/Tailwind';
import { useConfigContext } from '../../contexts/ConfigContext';

const NextAvailableInventoryButton = ({ item }) => {
    return (
        <Pressable
            style={tw`mr-[12px] my-2 rounded-[14px] bg-inventory-background flex-row`}
            onPress={item.handleClick}
        >
            <View style={tw`py-2 px-3`}>
                <View>
                    <Text style={tw`button-small`}>
                        {!item.title ? 'See next available' : item.title}
                    </Text>
                </View>
                <View>
                    <Text style={tw`label-xsmall`}>{item.amount_of_tables_label}</Text>
                </View>
                {item.label && (
                    <View style={tw`flex-row items-center mt-0.5`}>
                        <View style={tw`-left-0.5`}>
                            <GuestIcon color={'black'} />
                        </View>
                        <View>
                            <Text style={tw`label-xsmall text-black`}>{item.label}</Text>
                        </View>
                    </View>
                )}
            </View>
            <View style={tw`justify-center pl-2 pr-4`}>
                <ChevronRightIcon color='black' />
            </View>
        </Pressable>
    );
};

const CustomInventoryButton = ({ item }) => {
    return (
        <Pressable
            style={tw`my-2 py-2 px-3 mr-[12px] rounded-[14px] bg-inventory-background`}
            onPress={item.handleClick}
        >
            <View>
                <View>
                    <Text style={tw`button-small`}>Custom request</Text>
                </View>
                <View>
                    <Text style={tw`label-xsmall`}>From {item.price_starting_at}/pp</Text>
                </View>
                <View style={tw`flex-row items-center mt-0.5`}>
                    <View style={tw`-left-0.5`}>
                        <GuestIcon color={'black'} />
                    </View>
                    <View>
                        <Text style={[tw`label-xsmall text-black`]}>{item.label}</Text>
                    </View>
                </View>
            </View>
        </Pressable>
    );
};

const DefaultInventoryItemButton = ({
                                        item,
                                        showPrices,
                                        selectedItem,
                                        disabled,
                                        onPress = (item) => {
                                        },
                                    }) => {
    const isSelected = selectedItem?.uid === item.uid;

    const { dateTimeFormats } = useConfigContext();

    return (
        <Pressable
            onPress={() => onPress(item)}
            style={[
                tw`my-2 py-2 px-3 mr-[12px] rounded-[14px]`,
                isSelected ? tw`bg-black` : tw`bg-inventory-background`,
                disabled ? tw`opacity-25` : tw``,
            ]}
            disabled={disabled}
        >
            <View>
                <Text
                    style={[
                        tw`button-small`,
                        isSelected ? tw`text-white` : tw`text-black`,
                    ]}
                >
                    {item.datetime_label}
                </Text>
                {showPrices && (
                    <Text
                        style={[
                            tw`label-xsmall`,
                            isSelected ? tw`text-white` : tw`text-black`,
                        ]}
                    >
                        From {item.price__currency}/pp
                    </Text>
                )}
                <View style={tw`flex-row items-center  mt-0.5`}>
                    <View style={tw`absolute -left-0.5`}>
                        <GuestIcon color={isSelected ? 'white' : 'black'} />
                    </View>
                    <View style={tw`left-4`}>
                        <Text
                            style={[
                                tw`label-xsmall`,
                                isSelected ? tw`text-white` : tw`text-black`,
                            ]}
                        >
                            {item.label}
                        </Text>
                    </View>
                </View>
            </View>
        </Pressable>
    );
};

const RestaurantInventoryButtons = ({
                                        item,
                                        timezone = 'America/New_York',
                                        selectedItem = null,
                                        showPrices = false,
                                        onPress = () => {
                                        },
                                        disabled = false,
                                        type = { type },
                                    }) => {
    if (item.special) {
        return <CustomInventoryButton item={item} handleClick={onPress} />;
    } else if (item.nextAvailable) {
        return <NextAvailableInventoryButton item={item} />;
    } else {
        return (
            <DefaultInventoryItemButton
                item={item}
                onPress={onPress}
                disabled={disabled}
                showPrices={showPrices}
                selectedItem={selectedItem}
                timezone={timezone}
                type={type}
            />
        );
    }
};

export default memo(RestaurantInventoryButtons);
