import React, { ReactNode, useRef, useEffect, memo } from 'react';
import { View } from 'react-native';
import tw from '../../utils/Tailwind';
import Animated, { FadeIn } from 'react-native-reanimated';

type PopUpMenuProps = {
    children?: ReactNode;
    show: Boolean;
    dontShow: Function;
    style?: Object;
};

const PopUpMenu = ({
    children,
    show,
    dontShow,
    style = {},
}: PopUpMenuProps) => {
    const popUpMenuRef = useRef();

    useEffect(() => {
        //NOTE: this is a hack to get the menu to close when you click outside of it -- only works on web -- also CoPilot is mean to me
        const handleClickOutside = (event) => {
            if (
                popUpMenuRef.current &&
                !popUpMenuRef.current.contains(event.target)
            ) {
                dontShow();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popUpMenuRef]);

    return (
        <>
            {show ? (
                //                 <Animated.View
                //                     entering={FadeIn.duration(200)}
                // style={[tw`relative w-auto z-10`, style]}
                //                     ref={popUpMenuRef}
                //                 >
                <View
                    style={[
                        tw`absolute top-2 right-0 px-6 py-1 rounded-xl flex shadow-lg border border-brand-gray z-50 bg-brand-white`,
                        style,
                    ]}
                    ref={popUpMenuRef}
                >
                    {children}
                </View>
            ) : // </Animated.View>
            null}
        </>
    );
};

export default memo(PopUpMenu);
