import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function MapMarkerIcon({
  style = {},
  color = 'white',
  stroke = 'black',
  strokeWidth = 1,
  height = 13,
  width = 10,
}) {
  return (
    <Svg
      style={style}
      width={width}
      height={height}
      viewBox='-1 -1 12 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.75 5.3925C9.75 6.8816 9.20435 8.22971 8.32214 9.20557L8.34999 9.23638L4.94754 13L2.02743 9.76992C0.799411 8.79075 0 7.19489 0 5.3925C0 2.4143 2.18261 0 4.875 0C7.56739 0 9.75 2.4143 9.75 5.3925ZM4.87395 6.96412C6.02783 6.96412 6.96324 6.02872 6.96324 4.87484C6.96324 3.72096 6.02783 2.78555 4.87395 2.78555C3.72007 2.78555 2.78467 3.72096 2.78467 4.87484C2.78467 6.02872 3.72007 6.96412 4.87395 6.96412Z'
        fill={color}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    </Svg>
  );
}
