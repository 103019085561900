import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Pressable,
    ScrollView,
    SectionList,
    Text,
    TextInput,
    View,
} from 'react-native';
import { debounce } from 'lodash';
// import SystemModal from './SystemModal';
import SectionListItemLayout from '../utils/SectionListItemLayout';
import SearchThinIcon from '../icons/SearchThinIcon';
import SystemModal from '../modals/SystemModal';
import tw from '../../utils/Tailwind';
import { useConfigContext } from '../../contexts/ConfigContext';
import { CountryCode } from '../../gql/graphql';
import CountryCodeSearch from '../../classes/CountryCodes/CountryCodeSearch';
import SectionListGenerator from '../../classes/Formatting/SectionListGenerator';

const ITEM_HEIGHT = 55;
const HEADER_HEIGHT = 60;

function CountryCodeSelect({ isOpen, onDismiss, onSelect, dark = false }) {
    const { countryCodes } = useConfigContext();

    const [regionSections, setRegionSections] = useState(undefined);
    const [searchMatchingRegions, setSearchMatchingRegions] = useState<
        CountryCode[]
    >([]);

    const [searchTerms, setSearchTerms] = useState('');

    const sectionListRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            setSearchTerms('');
        }
    }, [isOpen]);

    useEffect(() => {
        if (!searchTerms) {
            setSearchMatchingRegions(
                SectionListGenerator.generate(countryCodes, 'name')
            );

            return;
        }

        setSearchMatchingRegions(
            SectionListGenerator.generate(
                new CountryCodeSearch(countryCodes).search(searchTerms),
                'name'
            )
        );
    }, [countryCodes, searchTerms]);

    const renderSectionHeader = ({ section: { title } }) => {
        return (
            <View
                style={[
                    tw`border-b justify-end h-[${HEADER_HEIGHT}px]`,
                    dark ? tw`border-[#ffffff1A]` : tw`border-[#0000001A]`,
                ]}
            >
                <Text
                    style={[
                        tw`label-large pb-2.5`,
                        dark ? tw`text-white` : tw`text-black`,
                    ]}
                >
                    {title.toUpperCase()}
                </Text>
            </View>
        );
    };

    const renderRegionItem = ({ item: region, index = 0 }) => {
        return (
            <Pressable
                key={region.alpha2 + index}
                activeOpacity={0.8}
                onPress={() => {
                    onSelect(region.alpha2);
                }}
                style={[
                    tw`flex-row items-center border-b h-[${ITEM_HEIGHT}px]`,
                    dark ? tw`border-[#ffffff1A]` : tw`border-[#0000001A]`,
                ]}
            >
                <View style={tw`flex-1`}>
                    <Text
                        style={[
                            tw`text-body pb-[1px]`,
                            dark ? tw`text-white` : tw`text-black`,
                        ]}
                    >
                        {region.name}
                    </Text>
                </View>
                <View>
                    <Text
                        style={[
                            tw`text-body pb-[1px] opacity-60`,
                            dark ? tw`text-white` : tw`text-black`,
                        ]}
                    >
                        {region.code}
                    </Text>
                </View>
            </Pressable>
        );
    };

    return (
        <SystemModal
            scrollable={false}
            onDismiss={onDismiss}
            visible={isOpen}
            name='Country Code Select'
            padding={tw`px-0`}
            backgroundStyle={dark ? tw`bg-brand-red` : null}
            closeButtonHeight={20}
            closeButtonWidth={20}
            dark={dark}
            style={tw`bg-brand-black bg-opacity-70 top-0 left-0 right-0 bottom-0 absolute py-6 `}
        >
            <View style={[tw`flex-1 max-h-screen `]}>
                <View
                    style={tw`px-5 pt-6 sm:w-full lg:max-w-225 mx-auto bg-brand-white`}
                >
                    <View
                        style={[
                            tw`flex-row items-center border-b pb-2.5`,
                            dark
                                ? tw`border-[#ffffff1A]`
                                : tw`border-[#0000001A]`,
                        ]}
                    >
                        <View style={tw`mt-[3px]`}>
                            <SearchThinIcon color={dark ? 'white' : 'black'} />
                        </View>
                        <TextInput
                            style={[
                                tw`flex-1 pl-2.5 label-large my-0 tracking-wide`,
                                dark ? tw`text-white` : tw`text-black`,
                                { outlineStyle: 'none' },
                            ]}
                            onChangeText={setSearchTerms}
                            autoComplete='off'
                            autoCorrect={false}
                            placeholder='Search'
                            placeholderTextColor={dark ? '#ffffff80' : null}
                        />
                    </View>
                </View>
                <ScrollView
                    keyboardShouldPersistTaps='always'
                    showsVerticalScrollIndicator={false}
                    style={tw`flex-1 my-3 mx-5 flex-1`}
                >
                    <SectionList
                        keyboardShouldPersistTaps='always'
                        ref={sectionListRef}
                        sections={searchMatchingRegions}
                        keyExtractor={(item, index) => {
                            return item.alpha2 + index;
                        }}
                        renderItem={renderRegionItem}
                        renderSectionHeader={renderSectionHeader}
                        ListFooterComponent={<View style={tw`pb-70`} />}
                        showsVerticalScrollIndicator={false}
                        stickySectionHeadersEnabled={false}
                        onScrollToIndexFailed={(info) =>
                            console.log('info', info)
                        }
                        getItemLayout={SectionListItemLayout({
                            getItemHeight: () => ITEM_HEIGHT,
                            listHeaderHeight: () =>
                                searchMatchingRegions.length * ITEM_HEIGHT,
                            getSectionHeaderHeight: () => HEADER_HEIGHT,
                        })}
                    />
                </ScrollView>
            </View>
        </SystemModal>
    );
}

export default CountryCodeSelect;
