import React, { createContext, PropsWithChildren, ReactElement, useContext, useEffect, useState } from 'react';
import { ConfigurationsQuery, CountryCode, DateTimeFormat } from '../gql/graphql';
import HttpClient from '../utils/HttpClient';
import { Configurations } from '../queries/Config';

const defaultDateTimeFormats = {
    moment: {
        date_long: 'MMMM D',
        date_short: 'MMM D',
        date_dow_short: 'ddd',
        date_dow_long: 'dddd',
        date_slashes: 'M/D/YYYY',
        date_slashes_short: 'M/D',
        time: 'h:mma',
        year: 'YYYY',
        date: '',
    },
    dateFns: {
        date_long: 'MMMM d',
        date_short: 'MMM d',
        date_dow_short: 'eee',
        date_dow_long: 'EEEE',
        date_slashes: 'M/d/yyyy',
        date_slashes_short: 'M/d',
        time: 'h:mmaaa',
        year: 'yyyy',
        date: '',
    },
};

const ConfigContext = createContext<Configuration>({
    countryCodes: [],
    dateTimeFormats: defaultDateTimeFormats,
});
export const useConfigContext = () => useContext(ConfigContext);

export type Configuration = {
    countryCodes: CountryCode[];
    dateTimeFormats: { moment: DateTimeFormat, dateFns: DateTimeFormat },
};

const ConfigProvider = (props: PropsWithChildren): ReactElement => {
    const [config, setConfig] = useState<Configuration>({
        countryCodes: [],
        dateTimeFormats: defaultDateTimeFormats,
    });

    useEffect(() => {
        HttpClient.graphqlQuery(Configurations, {}, true).then(
            (data: ConfigurationsQuery) => {
                setConfig({
                    countryCodes: data.countryCodes,
                    dateTimeFormats: data.dateTimeFormats,
                });
            },
        );
    }, []);

    return (
        <ConfigContext.Provider value={config}>
            {props.children}
        </ConfigContext.Provider>
    );
};

export default ConfigProvider;
