import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function ChevronDownIcon({ height = 7, width = 10, color }) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox='0 0 10 7'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <Path d='M9 1L5 5L1 1' stroke={color} strokeWidth='1.5' />
    </Svg>
  );
}
