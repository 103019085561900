import axios from 'axios';
import Constants from 'expo-constants';
import { DocumentNode, print } from 'graphql';
import { Platform } from 'react-native';

export enum HttpMethod {
    POST = 'post',
    GET = 'get',
    PUT = 'put',
}

export type Endpoint = {
    uri: string;
    method: HttpMethod;
    domain?: string;
};

export type Listener = {
    callback: CallableFunction;
    event: string;
    id: number;
};

export type HttpRequest = {
    endpoint: Endpoint;
    data: object;
};

export default {
    listeners: <Listener[]>[],
    languageTags: null,
    token: <string>'',

    emitEvent(event: string, payload: object = {}) {
        this.listeners.forEach((listener) => {
            if (listener.event === event) {
                listener.callback(payload);
            }
        });
    },

    addListener(event: string, callback: CallableFunction) {
        const listenerId =
            this.listeners.length === 0
                ? 0
                : this.listeners[this.listeners.length - 1].id + 1;

        this.listeners.push({
            id: listenerId,
            event,
            callback,
        });

        return listenerId;
    },

    removeListener(id: number) {
        this.listeners = this.listeners.filter(
            (listener) => listener.id !== id
        );
    },

    emitPosting() {
        this.emitEvent('posting');
    },

    emitPosted() {
        this.emitEvent('posted');
    },

    setToken(token: string): void {
        if (Platform.OS === 'web') {
            this.token = token;

            try {
                localStorage.setItem('DORSIA_TOKEN', token);
            } catch (e) {
                console.log('localStorage not accessible');
                // do nothing more
            }
        }
    },

    getToken(): string | null {
        if (this.token) {
            return this.token;
        }

        try {
            const localToken = localStorage.getItem('DORSIA_TOKEN');

            if (localToken) {
                return localToken;
            }
        } catch (e) {
            return null;
        }

        return null;
    },

    async http(endpoint: Endpoint, data: object = {}) {
        let headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: '',
            'X-Api-Version': '20240507',
        };

        const token = this.getToken();

        headers['Authorization'] = 'Bearer ' + token;

        // console.log(headers);

        const settings = {
            headers,
            timeout: 45000,
            method: endpoint.method.valueOf(),
            url: endpoint.uri,
            baseURL: endpoint.domain
                ? endpoint.domain
                : this.domain() + '/api/',
            credentials: 'include',
            data: <string | null>null,
            params: <object | null>{},
            mode: 'cors',
        };

        const url = settings.baseURL + settings.url;

        if (data && settings.method === 'post') {
            settings.data = JSON.stringify(data);
        }

        if (data && settings.method === 'get') {
            settings.params = data;
        }

        if (data && endpoint.uri === 'graphql/members') {
            settings.data = JSON.stringify(data);
            settings.baseURL = endpoint.domain
                ? endpoint.domain + '/'
                : this.domain() + '/';
            settings.params = null;
        }

        if (data && settings.method === 'put') {
            settings.params = data;
            this.emitEvent('posting');
        }

        if (settings.method === 'post') {
            this.emitEvent('posting');
        }

        return axios
            .request(settings)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            })
            .finally(() => {
                if (settings.method === 'post' || settings.method === 'put') {
                    this.emitEvent('posted');
                }
            });
    },

    domain() {
        return Constants.expoConfig.extra.api_domain;
    },

    graphqlQuery(
        query: DocumentNode,
        variables: any = {},
        open: boolean = false
    ) {
        const uri = open ? 'graphql/public' : 'graphql/concierge';

        return this.http(
            {
                method: HttpMethod.POST,
                uri: uri,
                domain: this.domain(),
            },
            {
                query: print(query),
                variables: variables,
            }
        )
            .then((res) => {
                // console.log(res);

                return res.data;
            })
            .catch((error) => {
                throw error;
            });
    },
};
