import React, { useEffect, useState } from 'react';
import { View, Text, Pressable } from 'react-native';
import tw from '../../utils/Tailwind';
import CustomImage from './CustomImage';
import { useAuthContext } from '../../contexts/AuthContext';
import ChevronDownIcon from '../icons/ChevronDownIcon';
import { useNavigation } from '@react-navigation/native';
import PopUpMenu from '../layout/PopUpMenu.Concierge';
import CloseIcon from '../icons/CloseIcon';
import HttpClient from '../../utils/HttpClient';

type ConciergeBlockProps = {
    currentUser: {
        first_name: String;
        last_name: String;
        email: String;
        phone: String;
        teams: Array<any>;
    };
    navigation: any;
};

const ConciergeBlock = ({}: ConciergeBlockProps) => {
    const navigation = useNavigation();
    const { currentUser } = useAuthContext();
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        if (!currentUser) return;
        window.intercomSettings = {
            app_id: 'lhco4xa2',
            name: currentUser?.first_name + ' ' + currentUser?.last_name,
            companies:
                currentUser?.teams.length > 0
                    ? currentUser?.teams.map((team) => {
                          return {
                              name: team?.name,
                          };
                      })
                    : [],
        };
    }, [currentUser]);

    return (
        <View style={tw`flex-1 items-end justify-end`}>
            <Pressable
                onPress={() => (!showMenu ? setShowMenu(true) : null)}
                style={tw`flex-row items-center rounded-full border border-brand-gray p-1`}
            >
                <CustomImage
                    style={tw`w-8 h-8 rounded-full border border-brand-gray`}
                    source={{
                        url: currentUser?.avatar_url
                            ? currentUser?.avatar_url
                            : 'https://picsum.photos/id/24/200/200',
                    }}
                />
                <View>
                    <Text style={tw`label-small px-2`}>
                        {currentUser?.first_name} {currentUser?.last_name}
                    </Text>
                </View>
                {!showMenu ? (
                    <View style={tw`pr-2 `}>
                        <ChevronDownIcon color={'black'} />
                    </View>
                ) : (
                    <View style={tw`pr-2 `}>
                        <CloseIcon width={10} color={'black'} />
                    </View>
                )}
            </Pressable>

            <View style={tw`flex-row items-center relative`}>
                <PopUpMenu
                    show={showMenu}
                    dontShow={() => setShowMenu(false)}
                    style={tw`lg:w-55`}
                >
                    <Pressable
                        style={tw`border-b border-brand-gray py-4 text-left`}
                        onPress={() => {
                            navigation.navigate('AccountScreen');
                        }}
                    >
                        <Text style={tw`bold`}>My account</Text>
                    </Pressable>
                    <Pressable
                        style={tw`border-b border-brand-gray py-4 text-left`}
                        onPress={() => {
                            navigation.navigate('BookingLinks');
                        }}
                    >
                        <Text style={tw`bold`}>View bookings</Text>
                    </Pressable>
                    <Pressable
                        style={tw`py-4 text-left`}
                        onPress={() => {
                            HttpClient.setToken('');
                            navigation.navigate('Login');
                        }}
                    >
                        <Text style={tw`bold text-brand-red`}>Log out</Text>
                    </Pressable>
                </PopUpMenu>
            </View>
        </View>
    );
};

export default ConciergeBlock;
