import React, { useEffect, useState } from 'react';
import { Image, Text, TextInput, Pressable, View } from 'react-native';
import tw from '../utils/Tailwind';
import API from '../utils/API';
import HttpClient from '../utils/HttpClient';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useAuthContext } from '../contexts/AuthContext';
import { DarkPhoneInput } from '../components/inputs/DarkPhoneInput';
import { PhoneInput } from '../components/inputs/PhoneInput';
import BasicButton from '../components/inputs/BasicButton';
import { set } from 'date-fns';
import ChevronLeftIcon from '../components/icons/ChevronLeftIcon';

const LoginScreen = ({ navigation }: NativeStackScreenProps<any>) => {
    const { fetchCurrentUser } = useAuthContext();

    const [method, setMethod] = useState('phone');
    const [contact, setContact] = useState<string>('');
    const [code, setCode] = useState<string>('');
    const [codeSent, setCodeSent] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    const sendCode = () => {
        const data =
            method === 'phone' ? { phone: contact } : { email: contact };

        API.auth.sendCode(data).then(() => setCodeSent(true));
    };

    const verifyCode = () => {
        const data =
            method === 'phone'
                ? { phone: contact, code }
                : { email: contact, code };

        API.auth
            .verifyCode(data)
            .then((data) => {
                HttpClient.setToken(data.token);

                fetchCurrentUser().then(() => {
                    navigation.navigate('Home');
                });
            })
            .catch(() => {
                setErrorMessage(
                    'Invalid code.\nPlease contact accounts@dorsia.com if you need further assistance.'
                );
            });
    };

    useEffect(() => {
        setErrorMessage(null);
    }, [code]);

    return (
        <View style={tw`bg-brand-black h-full w-full`}>
            <View style={tw`h-full flex flex-col justify-center items-center`}>
                <Image
                    style={tw`w-[349px] h-[62px]`}
                    source={require('../assets/brand/dorsia-logo.svg')}
                />
                <Text style={tw`text-white font-medium heading-2 bold mt-4`}>
                    Sign in to your account
                </Text>

                <View
                    style={tw`bg-shipGray-800 border border-gray-400 p-6 lg:p-12 rounded-lg mt-12 w-[450px] max-w-[90%] mx-auto`}
                >
                    {!codeSent && (
                        <>
                            <View style={tw`flex flex-row mb-4`}>
                                <Pressable
                                    onPress={() => {
                                        setMethod('phone');
                                        setContact('');
                                    }}
                                    style={[
                                        tw`mr-3`,
                                        method === 'phone'
                                            ? tw`border-b-white border-b`
                                            : tw``,
                                    ]}
                                >
                                    <Text style={tw`text-white`}>
                                        Via Phone
                                    </Text>
                                </Pressable>

                                <Pressable
                                    onPress={() => {
                                        setMethod('email');
                                        setContact('');
                                    }}
                                    style={[
                                        tw`mr-3`,
                                        method === 'email'
                                            ? tw`border-b-white border-b`
                                            : tw``,
                                    ]}
                                >
                                    <Text style={tw`text-white`}>
                                        Via Email
                                    </Text>
                                </Pressable>
                            </View>
                            <View>
                                {method === 'phone' && (
                                    <>
                                        <PhoneInput
                                            style={tw`ml-2 px-4 py-2 w-full text-lg rounded-xl`}
                                            textStyle={tw`text-black`}
                                            boxStyle={tw`rounded-lg border border-brand-black py-0 bg-brand-white`}
                                            labelStyle={tw`text-brand-white mb-2`}
                                            onParse={setContact}
                                            placeholder={'Phone'}
                                            value={contact}
                                            dark={false}
                                        />
                                        <Text style={tw`text-brand-white py-6`}>
                                            We'll send you a code via text
                                            message
                                        </Text>
                                    </>
                                )}
                                {method === 'email' && (
                                    <>
                                        <TextInput
                                            onChangeText={(e) => {
                                                const isValidEmail =
                                                    /^[\w-\+.]+@([\w-]+\.)+[\w-]+$/;
                                                if (
                                                    e &&
                                                    e.match(isValidEmail)
                                                ) {
                                                    setContact(e);
                                                } else {
                                                    setContact('');
                                                }
                                            }}
                                            style={tw`bg-white rounded-md text-lg w-full py-2 px-3`}
                                            placeholder={'Enter Email'}
                                            keyboardType={'email-address'}
                                        />
                                        <Text style={tw`text-brand-white py-6`}>
                                            We'll send you a code via email
                                        </Text>
                                    </>
                                )}

                                <BasicButton
                                    onPress={sendCode}
                                    disabled={!contact}
                                    style={tw`bg-brand-black py-4 px-8 rounded-xl`}
                                    disabledStyle={tw`bg-brand-black py-4 px-8 rounded-xl opacity-50`}
                                    justText='Send Code'
                                />
                            </View>
                        </>
                    )}

                    {codeSent && (
                        <View>
                            <TextInput
                                onChangeText={setCode}
                                style={tw`bg-white rounded-md text-lg w-full py-2 px-3 `}
                                placeholder={'Enter code'}
                            />
                            {errorMessage && (
                                <Text
                                    style={tw`text-brand-white pt-8 text-center`}
                                >
                                    {errorMessage}
                                </Text>
                            )}
                            <Pressable
                                onPress={verifyCode}
                                disabled={!code}
                                style={tw`mt-8 py-4 px-8 rounded-xl border border-brand-white`}
                            >
                                <Text style={tw`text-white text-center`}>
                                    Verify Code
                                </Text>
                            </Pressable>
                        </View>
                    )}
                </View>
            </View>
        </View>
    );
};

export default LoginScreen;
