import React, { ReactNode } from 'react';
import { View, Text, Pressable } from 'react-native';
import tw from '../../utils/Tailwind';
import Dorsia from '../icons/Logos/Dorsia';
import ConciergeBlock from '../items/ConciergeBlock';
import { Link, useNavigation } from '@react-navigation/native';
import API from '../../utils/API';
import HttpClient from '../../utils/HttpClient';
import { useAuthContext } from '../../contexts/AuthContext';

type HeaderProps = {
    topChild?: ReactNode;
    bottomChild?: ReactNode;
};

const Header = ({ topChild, bottomChild }: HeaderProps) => {
    const navigation = useNavigation();

    const { currentUser } = useAuthContext();

    return (
        <View
            style={tw`width-limit-wide py-4 mx-auto w-full px-4 desktop:px-0`}
        >
            <View style={tw`w-full`}>
                <View style={tw`flex-row justify-between items-center w-full`}>
                    <View style={tw`text-left`}>
                        <Pressable onPress={() => navigation.navigate('Home')}>
                            <View style={tw`hidden lg:flex`}>
                                <Dorsia
                                    scale={1.5}
                                    color={tw.color(`brand-black`)}
                                />
                            </View>
                            <View style={tw`flex lg:hidden`}>
                                <Dorsia
                                    scale={1.2}
                                    color={tw.color(`brand-black`)}
                                />
                            </View>
                        </Pressable>
                    </View>
                    {topChild ? topChild : null}
                    <View style={tw`flex-row justify-between items-center`}>
                        {currentUser && (
                            <ConciergeBlock currentUser={currentUser} />
                        )}
                    </View>
                </View>
                {bottomChild ? <>{bottomChild}</> : null}
            </View>
        </View>
    );
};

export default Header;
