const customColors = {
    gray: {
        // '50': '#f6f6f6',
        100: '#F2F2F2',
        200: '#D8D6D6',
        300: '#979797',
        400: '#777777',
        500: '#49484F',
        600: '#302F37',
        700: '#151518',
        // '800': '#2c2b2f',
        // '900': '#151518'
    },
    shipGray: {
        50: '#f5f5f5',
        100: '#ebebec',
        200: '#cececf',
        300: '#b1b1b3',
        400: '#767679',
        500: '#3b3b40',
        600: '#35353a',
        700: '#2c2c30',
        800: '#232326',
        900: '#1d1d1f',
    },
};

export default {
    extend: {
        screens: {
            sm: '380px',
            md: '420px',
            lg: '680px',
            tablet: '1024px',
            desktop: '1280px',
        },
        fontFamily: {
            sailec: ['SailecRegular'],
            'sailec-medium': ['SailecMedium'],
            'sailec-bold': ['SailecBold'],
            'sailec-italic': ['SailecItalic'],
        },
        colors: {
            // New
            brand: {
                black: '#0D0D0D',
                white: '#FFFFFF',
                gray: '#f1f1f0',
                red: '#FF5E30',
                blue: '#554FF1',
                lightBlue: '#D0DCDC',
                lightGray: '#ededed',
                checkboxLight: '#E7E4DF',
                checkboxDark: '#262525',
                radioLight: '#E7E4DF',
                radioDark: '#000000',
                defaultBackground: '#FDFDFD',
                backdrop: '#f5f5f5',
                taupe: '#F6F4EE',
                onboarding: '#0D0D0D',
                badgeRed: '#CD0000',
                badgeGreen: '#2F740E',
                blackBackground: '#0D0D0D',
                disabledButtonBackground: '#25221E',
                pageBackground: '#f1f1f1',
                altGray: '#ececec',
                bgGray: '#f7f7f7',
                altPurple: '#6939D3',
                altBlack: '#25221E',
                altWhite: '#FFFDF9',
            },
            transparent: 'transparent',
            gray: customColors.gray,
            shipGray: customColors.shipGray,
            dorsia: {
                purple: '#6939D3',
            },

            status: {
                green: {
                    background: '#81f4c3',
                    text: '#11563a',
                },
                red: {
                    background: '#ff9598',
                    text: '#661415',
                },
                yellow: {
                    background: '#f1d97f',
                    text: '#471801',
                },
                pink: {
                    background: '#ffc1c2',
                    text: '#471801',
                },
                gray: {
                    background: '#f1f1f0',
                    text: '#49484F',
                },
            },

            // primary: {
            //   red: '#FF5E30',
            //   blue: '#554FF1',
            //   lightBlue: '#D0DCDC',
            //   lightGray: '#ededed',
            //   checkboxLight: '#E7E4DF',
            //   checkboxDark: '#262525',
            //   radioLight: '#E7E4DF',
            //   radioDark: '#000000',
            //   defaultBackground: '#FDFDFD',
            //   backdrop: '#f5f5f5',
            //   taupe: '#F6F4EE',
            //   onboarding: '#0D0D0D',
            //   black: '#0D0D0D',
            //   badgeRed: '#CD0000',
            //   badgeGreen: '#2F740E',
            //   blackBackground: '#0D0D0D',
            //   disabledButtonBackground: '#25221E',
            //   pageBackground: '#f1f1f1',
            // },
            offBlack: {
                15: 'rgba(13, 10, 6, 0.15)',
            },
            green: {
                50: '#f6faf8',
                100: '#edf6f1',
                200: '#d1e8dd',
                300: '#b5dbc9',
                400: '#7ebfa0',
                500: '#47a477',
                600: '#40946b',
                700: '#357b59',
                800: '#2b6247',
                900: '#23503a',
            },
            errorRed: {
                50: '#f9f0f0',
                100: '#FEDEDE',
                200: '#e4bfbf',
                300: '#d69d9d',
                400: '#c06b6b',
                500: '#ac3939',
                600: '#9e3434',
                700: '#862b2b',
                800: '#ED6D75',
                900: '#BE3740',
            },
            'black-5': 'rgba(13, 10, 6, 0.05)',
            'black-8': 'rgba(13, 10, 6, 0.08)',
            'black-10': 'rgba(13, 10, 6, 0.10)',
            'black-15': 'rgba(13, 10, 6, 0.15)',
            'black-25': 'rgba(13, 10, 6, 0.25)',
            'black-30': 'rgba(13, 10, 6, 0.30)',
            'black-40': 'rgba(13, 10, 6, 0.4)',
            'black-50': 'rgba(13, 10, 6, 0.5)',
            'black-65': 'rgba(13, 10, 6, 0.65)',
            'black-75': 'rgba(13, 10, 6, 0.75)',
            'black-90': 'rgba(13, 10, 6, 0.9)',
            'white-65': 'rgba(255, 255, 255, 0.65)',
            'booking-disabled': 'rgba(25, 22, 20, 0.5)',
            'card-background': '#FDFDFD',
            'inventory-background': 'rgba(13, 10, 6, 0.05)',
            'filter-background': '#FFFDF9',
            'disabled-button-background': '#16191480',
        },
        fontSize: {
            sm: '13px',
            '6xl': '4rem',
            button: '14px',
            body: '16px',
            'body-med': '17px',
            'body-lg': '18px',
            heading: '22px',
            'heading-3': '23px',
            'heading-plus': '26px',
            'heading-med': '28px',
            'heading-lg': '32px',
            'heading-xlg': '36px',
            super: '48px',
            jumbo: '62px',
            title: '64px',
        },
    },
};
