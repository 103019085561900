import React, { useEffect, useRef } from 'react';
import {
    StyleSheet,
    Text,
    TextInput,
    TextInputProps,
    TextStyle,
    View,
    ViewStyle,
} from 'react-native';
import tw from '../../utils/Tailwind';

interface BasicTextInputProps extends TextInputProps {
    label: string;
    error: string | null | undefined;
    containerStyle: ViewStyle | undefined;
    labelStyle: TextStyle | undefined;
    textStyle: TextStyle | undefined;
    isReverseColor: boolean | undefined;
    prefix: string | undefined;
    focus: boolean | undefined;
}

export default function BasicTextInput(props: BasicTextInputProps) {
    const inputRef = useRef(null);

    useEffect(() => {
        if (props.focus) {
            inputRef.current.focus();
        }
    }, [props.focus]);

    const styles = StyleSheet.create({
        input: {
            outlineStyle: 'none',
            // border: '1px solid red',
        },
    });

    return (
        <View style={props.containerStyle ?? tw``}>
            {props.label && (
                <View style={tw`mb-2 flex-row items-center`}>
                    <Text
                        style={[
                            tw`flex-grow pr-4 font-sailec`,
                            props.isReverseColor
                                ? tw`text-black`
                                : tw`text-white`,
                            props.labelStyle ? props.labelStyle : {},
                        ]}
                    >
                        {props.label}
                    </Text>
                    {/* {error && errorPosition === 'top-right' && (
            <View style={[tw`flex-shrink`]}>
              <ErrorDisplay
                textStyle={tw`font-sailec text-red-400 text-right`}
                error={error}
              />
            </View>
          )} */}
                </View>
            )}
            <View
                style={[
                    tw`flex-row rounded`,
                    props.isReverseColor
                        ? tw`border-brand-altBlack border bg-white`
                        : tw`bg-[#262525]`,
                    props.error && props.errorPosition === 'top-right'
                        ? tw`border border-[#792E33]`
                        : null,
                    props.error &&
                    !props.isReverseColor &&
                    props.errorPosition === 'top-right'
                        ? tw`bg-[#491E21] `
                        : null,
                    props.style,
                ]}
            >
                {props.prefix && (
                    <Text
                        style={[
                            tw`body text-${
                                props.placeholderTextColor ?? 'black'
                            } py-4`,
                            props.textStyle,
                        ]}
                    >
                        {props.prefix}
                    </Text>
                )}
                <TextInput {...props} style={[tw`w-full  px-4`, props.style, styles.input]} />
            </View>
            {/* {error && errorPosition === 'bottom' && <ErrorDisplay error={error} />} */}
        </View>
    );
}
