import React, { useEffect, useState } from 'react';
import { Pressable, SafeAreaView, ScrollView, Text, View } from 'react-native';
import { GetRestaurantQuery, Reservation, Restaurant } from '../gql/graphql';
import HttpClient from '../utils/HttpClient';
import { GetRestaurant } from '../queries/Restaurants';
import API from '../utils/API';
import { useSearchContext } from '../contexts/SearchContext';
import {
    Availability,
    AvailabilityOption,
} from '../classes/Types/Availability';
import tw from '../utils/Tailwind';
import BookOnBehalfForm from '../components/inputs/Booking/BookOnBehalfForm';
import Header from '../components/layout/ConciergeHeader';
import { useAuthContext } from '../contexts/AuthContext';
import CustomCalendar from '../components/inputs/CalendarSingle';
import Shimmer from '../components/loaders/Shimmer';
import RestaurantInventorySliderControl from '../components/items/RestaurantInventorySliderControl';
import BackspaceIcon from '../components/icons/BackspaceIcon';
import ChevronLeftIcon from '../components/icons/ChevronLeftIcon';

const AccountScreen = ({ navigation, route }) => {
    const { currentUser } = useAuthContext();

    return (
        <SafeAreaView style={tw`flex-1 bg-brand-white `}>
            <View
                style={tw`w-full border-b border-brand-lightGray bg-brand-white `}
            >
                <Header
                    topChild={null}
                    bottomChild={
                        <View
                            style={tw`w-full flex-row items-center justify-start mx-auto pt-8 pb-4`}
                        >
                            <View style={tw`lg:w-1/10 pr-4`}>
                                <View style={tw`flex-row justify-start`}>
                                    <Pressable
                                        onPress={() => {
                                            if (navigation.canGoBack())
                                                navigation.goBack();
                                            else navigation.navigate('Home');
                                        }}
                                        style={tw`py-2 px-4 rounded flex-row items-center justify-center border border-brand-black rounded-full`}
                                    >
                                        <View style={tw`pr-2`}>
                                            <ChevronLeftIcon />
                                        </View>
                                        <Text style={tw`label-xxsmall`}>
                                            {' '}
                                            Back
                                        </Text>
                                    </Pressable>
                                </View>
                            </View>
                            <View
                                style={tw`flex-row items-center width-limit-short tablet:mx-auto`}
                            >
                                <Text
                                    numberOfLines={2}
                                    lineBreakMode='tail'
                                    style={tw`heading-2 bold`}
                                >
                                    Account
                                </Text>
                            </View>
                            <View
                                style={tw`hidden tablet:flex tablet:w-1/10 pr-4 `}
                            ></View>
                        </View>
                    }
                />
            </View>
            <ScrollView style={tw`flex-1 w-full bg-brand-bgGray`}>
                <View style={tw`width-limit-short mx-auto w-full my-8`}>
                    <View
                        style={tw`flex-1 w-full rounded-xl mt-12 p-6 bg-brand-white`}
                    >
                        <View style={tw` flex relative`}>
                            <Text style={tw`heading-3 bold`}>
                                {currentUser?.first_name}{' '}
                                {currentUser?.last_name}
                            </Text>

                            <Text style={tw`heading-4 underline mt-10`}>
                                {currentUser?.teams[0]?.name}
                            </Text>
                        </View>
                        <View style={tw`flex-row justify-end`}>
                            <Pressable
                                style={tw`flex px-6 py-2 w-45 border-2 border-brand-red rounded-xl `}
                                onPress={() => {
                                    HttpClient.setToken('');
                                    navigation.navigate('Login');
                                }}
                            >
                                <Text
                                    style={tw`label-small text-center text-brand-red`}
                                >
                                    Log Out
                                </Text>
                            </Pressable>
                        </View>
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

export default AccountScreen;
