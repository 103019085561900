import React, { useState, useEffect, useRef } from 'react';
import {
    Modal,
    Pressable,
    ScrollView,
    View,
    Animated,
    PanResponder,
    Dimensions,
    SafeAreaView,
} from 'react-native';
import CloseIcon from '../icons/CloseIcon';
import tw from '../../utils/Tailwind';
import Container from '../layout/Container';

const { height } = Dimensions.get('screen');

export default function SystemModal({
    visible = false,
    scrollable = true,
    onDismiss = () => {},
    name = 'System Modal',
    showCloseIcon = true,
    analyticsProps = {},
    children,
    dark = false,
    backgroundStyle = null,
    padding = '',
    transparent = true,
    presentationStyle = 'pageSheet',
    preventCloseGesture = false,
    closeButtonWidth = 16,
    closeButtonHeight = 16,
    style = {},
}) {
    const [show, setShow] = useState(visible);
    const [wantToClose, setWantToClose] = useState(false);
    const pan = useRef(new Animated.ValueXY()).current;

    useEffect(() => {
        setShow(visible);
    }, [visible]);

    const panResponder = PanResponder.create({
        onStartShouldSetPanResponder: () => true,
        onPanResponderMove: Animated.event(
            [
                null,
                {
                    dy: pan.y,
                },
            ],
            { useNativeDriver: false }
        ),
        onPanResponderRelease: (e, gestureState) => {
            if (gestureState.moveY > height * 0.05) {
                resetModal();
            } else {
                Animated.spring(pan, {
                    toValue: { x: 0, y: 0 },
                    useNativeDriver: false,
                }).start();
            }
        },
    });

    const resetModal = () => {
        handleDismiss();
        pan.setValue({ x: 0, y: 0 });
        setWantToClose(false);
    };

    const handleDismiss = () => {
        setShow(false);
        onDismiss(false);
    };

    const onScrollEndDrag = (e) => {
        if (preventCloseGesture) return;
        const { contentOffset } = e.nativeEvent;
        if (contentOffset.y < 0 && wantToClose) {
            resetModal();
        } else if (contentOffset.y < 0) {
            setWantToClose(true);
        } else {
            setWantToClose(false);
        }
    };

    return (
        <Modal
            visible={show}
            animationType='slide'
            transparent
            presentationStyle={!transparent ? presentationStyle : ''}
            style={tw`flex-1 pb-20 sm:w-full lg:max-w-125 mx-auto max-h-screen`}
        >
            <SafeAreaView
                style={[tw`flex-1 w-screen h-screen relative `, style]}
            >
                {/* {show && (
					<View
						style={tw`absolute w-screen h-screen bg-brand-black bg-opacity-70 top-0 left-0 right-0`}
					></View>
				)} */}
                <Animated.View style={[pan.getLayout()]}>
                    <Animated.View
                        {...panResponder.panHandlers}
                        style={[
                            tw`rounded-t-xl py-3 shadow sm:w-full lg:max-w-125 mx-auto`,
                            backgroundStyle
                                ? backgroundStyle
                                : dark
                                  ? tw`bg-black`
                                  : tw`bg-card-background`,
                        ]}
                    >
                        <View style={tw`flex-row justify-center mb-6`}>
                            <View
                                style={[
                                    tw`w-12 h-1 rounded-full`,
                                    dark ? tw`bg-white` : tw`bg-gray-300`,
                                ]}
                            />
                        </View>

                        {showCloseIcon && (
                            <View style={tw`absolute top-0 left-0`}>
                                <Pressable
                                    onPress={handleDismiss}
                                    style={tw`p-6`}
                                >
                                    <CloseIcon
                                        color={tw.color(
                                            dark ? 'white' : 'gray-900'
                                        )}
                                        width={closeButtonWidth}
                                        height={closeButtonHeight}
                                    />
                                </Pressable>
                            </View>
                        )}
                    </Animated.View>

                    {scrollable ? (
                        <ScrollView
                            showsVerticalScrollIndicator={false}
                            style={[
                                tw`h-full sm:w-full lg:max-w-125 mx-auto rounded-b-xl`,
                                dark ? tw`bg-black` : tw`bg-card-background`,
                            ]}
                        >
                            <Container
                                style={[tw``, padding ? padding : tw`px-6`]}
                            >
                                {children}
                            </Container>
                        </ScrollView>
                    ) : (
                        <View
                            style={[
                                tw`h-full sm:w-full lg:max-w-125 mx-auto  rounded-b-xl`,
                                dark ? tw`bg-black` : tw`bg-card-background`,
                            ]}
                        >
                            <Container style={[padding ? padding : tw`px-6`]}>
                                {children}
                            </Container>
                        </View>
                    )}
                </Animated.View>
            </SafeAreaView>
        </Modal>
    );
}
