import React, { useEffect, useState } from "react";
import tw from "../../utils/Tailwind";
import {
	Text,
	Pressable,
	ActivityIndicator,
	PressableProps,
	StyleProp,
	TextStyle, ViewStyle,
} from 'react-native';

interface BasicButtonProps extends PressableProps
{
	justText?: string
	disabledStyle?: StyleProp<ViewStyle>
	textStyle?: StyleProp<TextStyle>
	disabledTextStyle?: StyleProp<TextStyle>
	loadingColor?: string
	showLoading?: boolean
	loading?: boolean
	activeOpacity?: number
	disabled?: boolean
}

const BasicButton = ({
	style = {},
	disabledStyle = {},
	disabled = false,
	onPress = () => {},
	justText = '',
	children = <></>,
	textStyle = {},
	disabledTextStyle = {},
	showLoading = true,
	loadingColor = "#FFFFFF",
	activeOpacity = 0.9,
	testID = "button-test-id",
	loading = false,
}: BasicButtonProps) => {

	return (
		<Pressable
			accessibilityLabel={`Label for ${justText} button`}
			accessible={true}
			activeOpacity={activeOpacity}
			disabled={disabled || loading}
			onPress={onPress}
			style={[
				tw`flex-row py-3 px-4 rounded-full justify-center text-sm items-center relative`,
				disabled === false ? style : [disabledStyle, tw`opacity-80`],
			]}
			testID={testID}
		>
			<>
				{justText && (
					<Text
						style={[
							tw`text-white font-sailec-medium text-button text-center`,
							disabled === false
								? textStyle
								: [disabledTextStyle, tw`opacity-50`],
							showLoading && loading ? tw`opacity-0` : null,
						]}
					>
						{justText}
					</Text>
				)}
				{children ? children : null}
				{showLoading && loading && (
					<ActivityIndicator
						color={loadingColor}
						style={tw`absolute top-0 bottom-0 left-0 right-0`}
					/>
				)}
			</>
		</Pressable>
	);
};

export default BasicButton;
