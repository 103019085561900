import DerivableFilterOption from "../DerivableFilterOption";
import {FilterableRestaurant} from "../FilterableRestaurant";
import {SimpleFilter} from "../Filterable";
import {sortByNameAttribute} from "../../helpers";

export default class NeighborhoodOptions implements DerivableFilterOption {
    options(restaurants: FilterableRestaurant[]): SimpleFilter[] {
        const allOptions = {};

        restaurants.forEach((restaurant: FilterableRestaurant) => {
            if (! restaurant.neighborhood) {
                return;
            }

            allOptions[restaurant.neighborhood.id] = restaurant.neighborhood;
        })

        // @ts-ignore
        return Object.values(allOptions).sort(sortByNameAttribute);
    }
}