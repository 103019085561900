import Svg, { Path } from 'react-native-svg';

const FilterIconOutline = () => {
    return <Svg fill='none' viewBox='0 0 20 20' height={20} width={20}>
        <Path fill='#0D0D0D'
              d='M2 4.313C2 3.593 2.563 3 3.281 3h13.406A1.32 1.32 0 0 1 18 4.313c0 .312-.125.593-.313.812L12.5 11.531V16c0 .563-.469 1-1.031 1a.955.955 0 0 1-.625-.219L7.969 14.5a1.213 1.213 0 0 1-.469-.969v-2L2.281 5.125A1.224 1.224 0 0 1 2 4.312Zm1.719.187 5.094 6.281A.75.75 0 0 1 9 11.25v2.156L11 15v-3.75c0-.156.031-.313.156-.469L16.281 4.5H3.72Z' />
    </Svg>;
};

export default FilterIconOutline;
