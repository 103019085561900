import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function CalendarIcon({ height = 18, width = 18, style = {} }) {
    return (
        <Svg
            width={width}
            height={height}
            style={style}
            viewBox='0 0 18 18'
            fill='none'
        >
            <Path
                d='M3.92308 5.09375C3.67826 5.09375 3.44347 5.191 3.27036 5.36411C3.09725 5.53722 3 5.77201 3 6.01683V14.3245C3 14.5693 3.09725 14.8041 3.27036 14.9772C3.44347 15.1503 3.67826 15.2476 3.92308 15.2476H14.0769C14.3217 15.2476 14.5565 15.1503 14.7296 14.9772C14.9027 14.8041 15 14.5693 15 14.3245V6.01683C15 5.77201 14.9027 5.53722 14.7296 5.36411C14.5565 5.191 14.3217 5.09375 14.0769 5.09375H12.2308'
                stroke='#000001'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <Path
                d='M3 8.78906H15'
                stroke='#000001'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <Path
                d='M5.76953 3.25V6.94231'
                stroke='#000001'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <Path
                d='M12.2305 3.25V6.94231'
                stroke='#000001'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <Path
                d='M5.76953 5.09375H10.3849'
                stroke='#000001'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </Svg>
    );
}
