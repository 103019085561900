import HttpClient, { HttpMethod } from './HttpClient';

const API = {
    auth: {
        sendCode: async (data = {}) => {
            return await HttpClient.http(
                {
                    domain: HttpClient.domain(),
                    uri: '/concierge-api/code/send',
                    method: HttpMethod.POST,
                },
                data
            );
        },

        verifyCode: async (data = {}) => {
            return await HttpClient.http(
                {
                    domain: HttpClient.domain(),
                    uri: '/concierge-api/code/verify',
                    method: HttpMethod.POST,
                },
                data
            );
        },
    },

    payment: {
        conciergeIntent: async (): Promise<any> => {
            return await HttpClient.http(
                {
                    domain: HttpClient.domain(),
                    uri: `/concierge-api/setup-intent/`,
                    method: HttpMethod.GET,
                },
            );
        },
        paymentMethods: async (): Promise<any> => {
            return await HttpClient.http(
                {
                    domain: HttpClient.domain(),
                    uri: `/concierge-api/payment-methods/`,
                    method: HttpMethod.GET,
                },
            );
        },
    },

    restaurant: {
        availability: async (data: {
            party_size: number;
            start_date: string;
            end_date: string | null;
            restaurant_id: number;
        }): Promise<any> => {
            return await HttpClient.http(
                {
                    domain: HttpClient.domain(),
                    uri: `/api/restaurants/${data.restaurant_id}/availability`,
                    method: HttpMethod.GET,
                },
                data
            );
        },
    },

    booking: {
        book: async (data: {
            seats: number;
            restaurant: number | string;
            date: string;
            phone: string;
            confirmed_price: number;
            seating: number;
            first_name: string;
            last_name: string;
        }): Promise<any> => {
            return await HttpClient.http(
                {
                    domain: HttpClient.domain(),
                    uri: `/api/restaurants/${data.restaurant}/book-on-behalf`,
                    method: HttpMethod.POST,
                },
                data
            );
        },

        cancel: async (data: { uuid: String }): Promise<any> => {
            return await HttpClient.http(
                {
                    domain: HttpClient.domain(),
                    uri: `/api/reservations/${data.uuid}/book-on-behalf/cancel`,
                    method: HttpMethod.POST,
                },
                data
            );
        },

        fullBook: async (data: {
            seats: number;
            restaurant: number | string;
            date: string, //format('Y-m-d'),
            phone: string;
            confirmed_price: number;
            seating: number;
            name: string,
            email: string,
            cardholder_name: string,
            payment_id: string,
            zip_code: string,
            restaurant_id: string,
            seating_id: number,
            party: number,
            time: string,
            use_credits: 1,
            promo: null,
            one_time_use: false,
        }): Promise<any> => {
            return await HttpClient.http(
                {
                    domain: HttpClient.domain(),
                    uri: `concierge-api/reservations`,
                    method: HttpMethod.POST,
                },
                data
            );
        },
        
    },
};

export default API;
