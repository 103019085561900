import Filterable, {FilterData} from "../Filterable";
import {FilterableRestaurant} from "../FilterableRestaurant";

export default class CuisineFilter implements Filterable {
    filter(restaurants: FilterableRestaurant[], data: FilterData): FilterableRestaurant[] {
        if (! data.cuisines.length) {
            return restaurants;
        }

        return restaurants.filter((restaurant: FilterableRestaurant): boolean => {
            return restaurant.cuisines

                // Map to list of IDs
                .map((cuisine: {id: number}): number => cuisine.id)

                // Filter down cuisines to those that match FilterDataIds
                .filter((id) => data.cuisines.includes(id)).length > 0;
        });
    }
}