import { graphql } from '../gql';

const Reservations = graphql(`

    query Reservations($column: QueryReservationsOrderByColumn!, $order: SortOrder!, $page: Int, $status: [String], $first: Int) {
        reservations(orderBy: [{ column: $column, order: $order }], page: $page, byStatuses: $status, first: $first) {

            paginatorInfo {
                hasMorePages
                currentPage
                lastPage
                perPage
            }

            data{
                uuid
                table_minimum__currency
                price_per_person__currency
                party_size
                table_type
                created_at

                restaurant{
                    name
                    address
                    phone_formatted
                    area{
                        name
                    }
                }
                user {
                    first_name
                    last_name
                    phone_formatted
                }
                reservation_at_local__formatted {
                    date_short
                    date_dow_short
                    time
                }
                reservation_at_local__request_formatted {
                    date_short
                    date_dow_short
                    time
                }
                booking_link_status
                booking_link
            }
        }
    }

`);

export { Reservations };