import React from 'react';
import Svg, { Path } from 'react-native-svg';

const ThreeDotIcon = () => {
    return <Svg fill="none" height={20} width={20} viewBox="0 0 20 20">
        <Path fill="#0D0D0D" d="M16.5 10c0 .563-.313 1.031-.75 1.313-.469.28-1.063.28-1.5 0A1.496 1.496 0 0 1 13.5 10c0-.531.281-1 .75-1.281.438-.281 1.031-.281 1.5 0 .438.281.75.75.75 1.281Zm-5 0c0 .563-.313 1.031-.75 1.313-.469.28-1.063.28-1.5 0A1.496 1.496 0 0 1 8.5 10c0-.531.281-1 .75-1.281.438-.281 1.031-.281 1.5 0 .438.281.75.75.75 1.281ZM5 11.5a1.496 1.496 0 0 1-1.313-.75c-.28-.438-.28-1.031 0-1.5.282-.438.75-.75 1.313-.75.531 0 1 .313 1.281.75.282.469.282 1.063 0 1.5-.281.469-.75.75-1.281.75Z"/>
    </Svg>
};

export default ThreeDotIcon;
