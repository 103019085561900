import React from 'react';
import { View, Text, Pressable } from 'react-native';
import tw from '../../utils/Tailwind';
// import { useAnalytics } from '@segment/analytics-react-native';

// const analyticsProps = {
//   component: 'Party Size Select',
//   flow: 'Filters',
// };

export default function PartySizeSelect({
  onPartyChange,
  partySize,
  allowZero = false,
  minPartySize = 2,
  maxPartySize = 12,
  allowAnyIgnoreMinSize = false,
}) {
  // const { track } = useAnalytics();

  const handlePartyDecrement = () => {
    // track('Decrement Party Size Pressed', {
    //   ...analyticsProps,
    //   previous_size: formatPartyLabel(),
    // });
    onPartyChange(partySize - 1 >= minPartySize ? partySize - 1 : null);
  };

  const handlePartyIncrement = () => {
    // track('Increment Party Size Pressed', {
    //   ...analyticsProps,
    //   previous_size: formatPartyLabel(),
    // });
    onPartyChange(partySize < minPartySize ? minPartySize : partySize + 1);
  };

  const shouldBeDisabled = (isIncrement) => {
    if (!isIncrement && partySize === null) return true;
    if (!isIncrement && !allowAnyIgnoreMinSize && partySize <= minPartySize)
      return true; // this prevents the user from making partySize null/Any on custom request
    if (!isIncrement && allowAnyIgnoreMinSize) return false; // this allows the user to make partySize null/Any on filter page
    return isIncrement
      ? partySize >= maxPartySize
      : partySize <= minPartySize || !partySize;
  };

  const formatPartyLabel = () => {
    return partySize ? partySize : allowZero ? 0 : 'Any';
  };

  const renderMinusRadialButton = () => {
    return (
      <Pressable
        style={[
          shouldBeDisabled(false)
            ? tw`bg-filter-background`
            : tw`bg-white shadow-button`,
          tw`absolute left-0 rounded-full h-8 w-8 items-center justify-center`,
        ]}
        onPress={handlePartyDecrement}
        disabled={shouldBeDisabled(false)}
      >
        <Text
          style={tw`font-medium text-[16px] ${
            shouldBeDisabled(false) ? 'text-black-40' : ''
          }`}
        >
          -
        </Text>
      </Pressable>
    );
  };

  const renderPlusRadialButton = () => {
    return (
      <Pressable
        style={[
          shouldBeDisabled(true)
            ? tw`bg-filter-background`
            : tw`bg-white shadow-button`,
          tw`absolute right-0 rounded-full h-8 w-8 items-center justify-center`,
        ]}
        onPress={handlePartyIncrement}
        disabled={shouldBeDisabled(true)}
      >
        <Text
          style={tw`font-medium text-[16px] ${
            shouldBeDisabled(true) ? 'text-black-40' : ''
          }`}
        >
          +
        </Text>
      </Pressable>
    );
  };

  return (
    <View style={tw`flex-row justify-between`}>
      <View style={tw`flex-1.8`}>
        <Text style={tw`heading-4`}>Party size</Text>
      </View>
      <View style={tw`flex-1 flex-row justify-center items-center`}>
        {renderMinusRadialButton()}
        <View>
          <Text style={tw`heading-Constants`}>{formatPartyLabel()}</Text>
        </View>
        {renderPlusRadialButton()}
      </View>
    </View>
  );
}
