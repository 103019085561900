import { graphql } from "../gql";

const Areas = graphql(`
	query Areas {
		areas {
      data {
        id
        name
      }
    }
	}
`);

export { Areas };
